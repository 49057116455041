import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, CogIcon } from '@heroicons/react/solid'

import classNames from 'classnames'
import React, { Fragment, useState } from 'react'

import ConfirmationDialog from '../shared/ConfirmationDialog'

const Dropdown = props => {
  const icon = props.icon || 'chevronDown'
  const direction = props.direction || 'left'
  const [dialogTitle, setDialogTitle] = useState()
  const [dialogDescription, setDialogDescription] = useState()
  const [dialogUrl, setDialogUrl] = useState()
  const [dialogMethod, setDialogMethod] = useState()
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const handleClick = (menuItem) => {
    setDialogTitle(menuItem.title)
    setDialogDescription(menuItem.confirm)
    setDialogUrl(menuItem.url)
    setDialogMethod(menuItem.method)
    setDialogIsOpen(true)
  }

  return (
    <>
      <ConfirmationDialog
        title={dialogTitle}
        description={dialogDescription}
        url={dialogUrl}
        method={dialogMethod}
        isOpen={dialogIsOpen}
        setIsOpen={setDialogIsOpen}
      />
      <Menu as='div' className='h-full'>
        {({ open }) => (
          <div className='flex h-full'>
            <Menu.Button className='my-auto text-gray-600'>
              {props.title}
              {icon === 'cog' && <CogIcon className={classNames('inline w-5 h-5 text-gray-600', { 'ml-2 -mr-1': props.title })} aria-hidden='true' />}
              {icon === 'chevronDown' && <ChevronDownIcon className={classNames('inline w-5 h-5 text-gray-600', { 'ml-2 -mr-1': props.title })} aria-hidden='true' />}
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                static
                className={classNames('absolute w-56 mt-12 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none', { '-left-3': direction === 'left' }, { 'right-0 origin-top-right': direction === 'right' })}
              >
                {props.links.map((menuItems, i) => {
                  return (
                    <div key={`menu-item-${i}`} className='px-1 py-1'>
                      {menuItems.map(menuItem => {
                        return (
                          <Menu.Item key={menuItem.title}>
                            {({ active }) => (
                              <button
                                className={classNames('group flex rounded-md items-center w-full px-2 py-2 text-gray-600', { 'font-bold text-blue-600': active })}
                                onClick={menuItem.confirm && (() => handleClick(menuItem))}
                              >
                                {menuItem.confirm
                                  ? <span className={`w-full text-${direction}`}>{menuItem.title}</span>
                                  : <a href={menuItem.url} className={`w-full text-${direction}`} data-method={menuItem.method}>{menuItem.title}</a>}
                              </button>
                            )}
                          </Menu.Item>
                        )
                      })}
                    </div>
                  )
                })}
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
    </>
  )
}

export default Dropdown
