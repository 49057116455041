import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import Buttons from '../../shared/Buttons'
import Title from '../../shared/Title'

const Step6 = ({ ad, exchangeRate, prevStep, url, upsalePrices, user, googleTagId, id, handleAlert }) => {
  const { handleSubmit, register, setValue, watch } = useForm({ defaultValues: Object.assign({ 'ad[paypal_transaction_id]': ad.paypal_transaction_id }, ad) })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = data => {
    setDisabled(true)

    // we're not sure we have control of anything after submitting the form, so we track the event before submitting the form
    window.fbq('trackCustom', 'Submit Ad')

    window.gtag('event', 'conversion', { send_to: 'AW-306649816/By1WCN3srIADENi1nJIB' })

    formRef.current.submit()
    if (googleTagId) { window.gtag('event', 'conversion', { send_to: googleTagId + '/0xIQCJTv04ADENi1nJIB' }) }
  }

  const formRef = useRef(null)
  const [paypalError, setPaypalError] = useState(false)
  const [paypalLoading, setPaypalLoading] = useState(false)
  const [paypalSuccessful, setPaypalSuccessful] = useState(false)

  const paymentMethod = watch('ad[payment_method]')
  const amount = ad.upsales.map(upsale => upsalePrices[upsale]).reduce((a, b) => a + b, 0)

  const [disabled, setDisabled] = useState(amount > 0)

  useEffect(() => {
    window.scrollTo(0, 0)
    setDisabled(paymentMethod === null || paymentMethod === 'paypal')
  }, [paymentMethod])

  const createOrder = (data, actions) => {
    const fullName = ad.user?.full_name || user.fullName
    const phone = ad.user?.phone || user.phone

    return actions.order.create({
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      },
      intent: 'AUTHORIZE',
      payer: {
        address: {
          address_line_1: 'Dirección',
          address_line_2: 'San José',
          admin_area_1: 'Cantón',
          admin_area_2: 'Distrito',
          country_code: 'CR',
          postal_code: '10107'
        },
        email_address: user.email,
        name: {
          given_name: fullName?.split(' ').slice(-1).join(' ') || 'Juan',
          surname: fullName?.split(' ').slice(0, -1).join(' ') || 'Perez'
        },
        phone: {
          phone_number: {
            national_number: phone?.replace(/\+| |-/gi, '') || '50688888888'
          }
        }
      },
      purchase_units: [{
        amount: {
          currency_code: 'USD',
          value: amount
        }
      }]
    })
  }

  const handleApprove = (data, actions) => {
    setPaypalLoading(true)
    actions.order.authorize().then(authorization => {
      const authorizationId = authorization.purchase_units[0].payments.authorizations[0].id
      setValue('ad[paypal_authorization_id]', authorizationId)
      setPaypalLoading(false)
      setPaypalSuccessful(true)
      setDisabled(false)
    })
  }

  const handleError = error => {
    if (error) {
      setPaypalError(true)
    }
  }

  return (
    <PayPalScriptProvider options={{ 'client-id': document.querySelector('meta[name="paypal-client-id"]').content, intent: 'authorize' }}>
      <form action={url} encType='multipart/form-data' method='post' ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <input type='hidden' name='_method' value='patch' />
        <input type='hidden' name='authenticity_token' value={document.querySelector('meta[name=csrf-token]').content} />
        <input type='hidden' name='ad[payment_method]' value={paymentMethod} />

        {ad.upsales && ad.upsales.map(upsale => (
          <div key={upsale}>
            <input type='hidden' name='ad[upsales_attributes][][upsale_type]' value={upsale} />
            <input type='hidden' name='ad[upsales_attributes][][payment_method]' value={paymentMethod} />
          </div>
        ))}

        <Title text='Pago' />

        <div className='container px-8 pt-4 mx-auto md:w-3/4 md:p-12'>
          {amount === 0
            ? (
              <>
                <div className='text-center'>
                  Anuncio gratuito.
                </div>

                <div className='text-center'>
                  Favor de tocar el botón de Finalizar para completar el proceso.
                </div>

                <div className='text-center'>
                  Podrá ver su anuncio dentro de los resultados de búsqueda después de que éste sea aprobado.
                </div>
              </>
              )
            : (
              <>
                {!paymentMethod && !paypalSuccessful && (
                  <div id='buttons' className='grid items-end grid-cols-1 gap-2 my-4 md:my-10 md:grid-cols-3 md:gap-0'>
                    <label className='py-10 text-center bg-white rounded shadow md:py-0 md:bg-transparent md:shadow-none md:filter none'>
                      <img src='https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg' border='0' alt='PayPal Acceptance Mark' />
                      <input type='radio' {...register('ad[payment_method]', { required: true })} value='paypal' className='absolute invisible md:relative md:visible' />
                      <span className='md:ml-2'>PayPal / Tarjeta Débito - Crédito</span>
                    </label>
                    <label className='py-10 text-center bg-white rounded shadow md:py-0 md:bg-transparent md:shadow-none md:filter none'>
                      <img src={require('../../../images/paymethods/sinpe.webp')} width='200' height='200' className='mx-auto' />
                      <input type='radio' {...register('ad[payment_method]', { required: true })} value='sinpe' className='absolute invisible md:relative md:visible' />
                      <span className='md:ml-2'>Sinpe Movil</span>
                    </label>
                    <label className='py-10 text-center bg-white rounded shadow md:py-0 md:bg-transparent md:shadow-none md:filter none'>
                      <img src={require('../../../images/paymethods/banco.webp')} width='150' height='150' top='200%' className='mx-auto' />
                      <input type='radio' {...register('ad[payment_method]', { required: true })} value='transfer' className='absolute invisible md:relative md:visible' />
                      <span className='md:ml-2'>Transferencia Bancaria</span>
                    </label>
                  </div>
                )}

                {paymentMethod === 'transfer' && (
                  <div className='mx-auto space-y-4 md:w-1/2'>
                    <div className='text-center'>
                      <p><strong>ORDEN DE COMPRA RECIBIDA</strong></p>
                    </div>
                    <p>Se ha seleccionado "Transferencia Bancaria" como método de pago.</p>
                    <p>El detalle de pago debe incluir el "ID de anuncio".</p>
                    <ul className='ml-8 list-disc'>
                      <li><strong>ID de anuncio:</strong> <b style={{ color: '#2563eb' }}>{id}</b></li>
                    </ul>
                    <p>El total a pagar es de ${amount} (tipo de cambio, ₡{exchangeRate}) = <b style={{ color: '#2563eb' }}>₡{Intl.NumberFormat('en').format(amount * exchangeRate)}</b></p>

                    <p>Es necesario que nos envíe una copia del comprobante al correo electrónico <a href='mailto:soporte@dunu506.com' target='_blank' rel='noreferrer'>soporte@dunu506.com</a> o a nuestro WhatsApp al 7217-8526 para nosotros poder identificar el pago rápidamente.</p>
                    <p><strong>OPCIONAL:</strong> Si gusta factura electrónica favor incluir su nombre completo y número de cédula.</p>

                    <ul className='ml-8 list-disc'>
                      <li><strong>Nombre:</strong> METAL DUNES BUSINESS SOCIEDAD ANONIMA</li>
                      <li><strong>Cédula Jurídica:</strong> 3-101-465700</li>
                      <li><strong>Banco: BAC Costa Rica</strong></li>
                      <ul className='ml-4 list-disc'>
                        <li>(₡) Número de cuenta cliente (₡): 947164307</li>
                        <li>(₡) Número de cuenta IBAN (₡): CR89010200009471643072</li>
                        <li>($) Número de cuenta cliente ($): 947164299</li>
                        <li>($) Número de cuenta IBAN ($): CR45010200009471642991</li>
                      </ul>
                    </ul>
                    <p><strong>NOTA:</strong> Las activaciones pueden demorar hasta 48 horas.</p>
                    <p>Favor de tocar el botón de <strong>Finalizar</strong> para completar el proceso.</p>
                    <p>Podrá ver su anuncio dentro de los resultados de búsqueda después de que éste sea aprobado.</p>
                  </div>
                )}

                {paymentMethod === 'sinpe' && (
                  <div className='mx-auto space-y-4 md:w-1/2'>
                    <div className='text-center'>
                      <p><strong>ORDEN DE COMPRA RECIBIDA</strong></p>
                    </div>
                    <p>Se ha seleccionado "SINPE Movil" como método de pago.</p>
                    <p>El detalle de pago debe incluir el "ID de anuncio".</p>
                    <ul className='ml-8 list-disc'>
                      <li><strong>ID de anuncio:</strong> <b style={{ color: '#2563eb' }}>{id}</b></li>
                    </ul>
                    <p>El total a pagar es de ${amount} (tipo de cambio, ₡{exchangeRate}) = <b style={{ color: '#2563eb' }}>₡{Intl.NumberFormat('en').format(amount * exchangeRate)}</b></p>
                    <ul className='ml-8 list-disc'>
                      <li><strong>Número Móvil:</strong> 7217 - 8526</li>
                      <li><strong>Nombre de Cuenta:</strong> Metal_dunes_business</li>
                    </ul>
                    <p><strong>NOTA:</strong> Las activaciones pueden demorar hasta 48 horas.</p>
                    <p><strong>OPCIONAL:</strong> Si gusta factura electrónica favor enviar su nombre completo y número de cédula a <a href='mailto:soporte@dunu506.com' target='_blank' rel='noreferrer'>soporte@dunu506.com</a>.</p>
                    <p>Favor de tocar el botón de <strong>Finalizar</strong> para completar el proceso.</p>
                    <p>Podrá ver su anuncio dentro de los resultados de búsqueda después de que éste sea aprobado.</p>
                  </div>
                )}

                {paymentMethod === 'paypal' && (
                  <>
                    <input type='hidden' {...register('ad[paypal_authorization_id]', { required: true })} />

                    <div className='mx-auto md:w-1/2'>
                      {paypalError && (
                        <div className='text-center'>
                          Hubo un problema procesando el pago, por favor vuelve a intentarlo.
                        </div>
                      )}

                      {paypalLoading && (
                        <div className='text-center'>
                          El pago esta siendo autorizado.
                        </div>
                      )}

                      {paypalSuccessful && (
                        <>
                          <div className='text-center'>
                            El pago fue autorizado con éxito.
                          </div>

                          <div className='text-center'>
                            Le recordamos que el cobro no se hará efectivo hasta que el anuncio sea aprobado.
                          </div>
                        </>
                      )}

                      {ad.paypal_transaction_id && (
                        <div className='text-center'>
                          Este anuncio ya esta pago.
                        </div>
                      )}

                      {!ad.paypal_transaction_id && !paypalSuccessful && (
                        <PayPalButtons
                          createOrder={createOrder}
                          disabled={paypalLoading}
                          onApprove={handleApprove}
                          onError={handleError}
                        />
                      )}
                    </div>
                  </>
                )}
              </>
              )}
        </div>

        <Buttons
          prevStep={prevStep}
          disabled={disabled}
          finish
          handleAlert={handleAlert}
        />
      </form>
    </PayPalScriptProvider>
  )
}

export default Step6
