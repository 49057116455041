import React, { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const ConfirmationDialog = props => {
  const confirmButtonRef = useRef()

  return (
    <Transition show={props.isOpen}>
      <Dialog static className='fixed z-10 inset-0' initialFocus={confirmButtonRef} open={props.isOpen} onClose={() => props.setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-100'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-75'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay className='bg-gray-700 bg-opacity-60 fixed inset-0' />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter='ease-out duration-100'
          enterFrom='opacity-0 transform scale-95'
          enterTo='opacity-100 transform scale-100'
          leave='ease-in duration-75'
          leaveFrom='opacity-100 transform scale-100'
          leaveTo='opacity-0 transform scale-95'
        >
          <div className='flex h-screen'>
            <div className='my-auto mx-4 p-8 z-10 text-center bg-white rounded shadow-lg md:mx-auto md:w-1/3 '>
              <Dialog.Title className='text-lg text-gray-700 font-bold'>{props.title}</Dialog.Title>
              <Dialog.Description className='text-lg text-gray-700 mt-6 whitespace-pre-wrap'>{props.description}</Dialog.Description>
              <div className='flex mx-auto md:float-right mt-8 space-x-5'>
                <button onClick={() => props.setIsOpen(false)} className='px-4 py-2 border border-gray-500 rounded w-1/2'>Cancelar</button>
                {props.method
                  ? (
                    <>
                      <form action={props.url} method='post' className='flex w-1/2'>
                        <input type='hidden' name='_method' value={props.method} />
                        <input type='hidden' name={document.querySelector('meta[name=csrf-param]').content} value={document.querySelector('meta[name=csrf-token]').content} />
                        <input type='submit' ref={confirmButtonRef} className='block px-4 py-2 bg-blue-600 text-white rounded w-full' value='Confirmar' />
                      </form>
                    </>
                    )
                  : <a href={props.url} ref={confirmButtonRef} className='block px-4 py-2 bg-blue-600 text-white rounded w-1/2'>Confirmar</a>}
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

export default ConfirmationDialog
