import React, { Fragment, useRef, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { useForm } from 'react-hook-form'

import Buttons from '../../../shared/Buttons'
import Title from '../../../shared/Title'

import FileUploader from './ImageUploader'

const NAUTICAL = 6

const Step4 = ({ ad, nextStep, prevStep, url, id }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const maxNumberOfFiles = parseInt(ad.vehicle_type_id) === NAUTICAL ? 30 : 10
  const [doneUploading, setDoneUploading] = useState(ad.images.length)

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const { handleSubmit } = useForm({ defaultValues: ad })
  const [backDisabled, setBackDisabled] = useState(false)

  const formRef = useRef(null)

  const onSubmit = data => {
    if (ad.id && ad.state !== 'draft') {
      formRef.current.submit()
    } else {
      nextStep()
    }
  }

  return (
    <>
      <Transition show={dialogIsOpen}>
        <Dialog static className='fixed inset-0 z-40 py-20 overflow-auto md:py-0' open={dialogIsOpen} onClose={() => setDialogIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-700 bg-opacity-60' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0 transform scale-95'
            enterTo='opacity-100 transform scale-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100 transform scale-100'
            leaveTo='opacity-0 transform scale-95'
          >
            <div className='flex h-screen'>
              <div className='relative z-10 pb-12 mx-4 my-auto bg-white rounded shadow-lg h-max p-7 md:px-14 md:mx-auto md:w-1/2'>
                <button onClick={() => setDialogIsOpen(false)} className='absolute top-5 right-5'>
                  <img src={require('../../../../images/close.svg')} />
                </button>
                <Dialog.Title className='text-lg font-bold text-center text-blue-600'>Consejos para tomar mejores fotografías de su vehículo</Dialog.Title>
                <ul className='mt-8 ml-6 space-y-3 text-gray-700 list-disc'>
                  <li>Suba fotografías reales y actuales de su vehículo.</li>
                  <li>Evite fotografías con logos, textos, o fotomontajes.</li>
                  <li>Priorice fotografías cuadradas para que se vean bien en computadoras y en celulares.</li>
                  <li>Recomendamos subir fotografías de todos los ángulos de tu vehículo y del interior (si corresponde).</li>
                  <li>Los anuncios con más de 4 fotografías tienen más posibilidades de venderse!</li>
                  <li>Recomendamos ubicar su vehículo contra un fondos liso o simple (ej. una pared) y evitar fondos muy complejos.</li>
                  <li>Tome las fotografías con buena luz, idealmente de día.</li>
                  <li>Evite añadir filtros o efectos a sus fotografías.</li>
                </ul>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>

      <form action={url} method='post' ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <input type='hidden' name='_method' value='patch' />
        <input type='hidden' name='authenticity_token' value={document.querySelector('meta[name=csrf-token]').content} />

        <Title text='Fotografías de su vehículo' />

        <div className='container px-8 pt-4 mx-auto space-y-4 md:p-12 md:space-y-8'>
          <div className='mx-auto text-gray-700 md:text-center'>
            <p className='font-bold'>Suba hasta {maxNumberOfFiles} fotos y seleccione cuál será la foto de portada.</p>
            <p>Le recomendamos ver estos <button type='button' className='text-blue-600 underline' onClick={() => setDialogIsOpen(true)}>consejos de fotografía </button> para maximizar su anuncio.</p>
          </div>
          <FileUploader
            maxFiles={maxNumberOfFiles}
            ad={ad}
            id={id}
            setBackDisabled={setBackDisabled}
            setDoneUploading={setDoneUploading}
          />
        </div>

        <Buttons
          prevStep={prevStep}
          backDisabled={backDisabled}
          disabled={!doneUploading}
          finish={ad.id && ad.state !== 'draft'}
        />
      </form>
    </>
  )
}

export default Step4
