import React, { useState } from 'react'

import MyAdsSearch from '../MyAdsSearch'
import chevronDownImg from '../../images/my_ads/chevron-down.svg'
import chevronUpImg from '../../images/my_ads/chevron-up.svg'

const MyAdsSearchOptions = props => {
  const [searchMenuIsOpen, setSearchMenuIsOpen] = useState(false)
  const [sortMenuIsOpen, setSortMenuIsOpen] = useState(false)

  const handleSearchMenuButton = (e) => {
    setSortMenuIsOpen(false)
    setSearchMenuIsOpen(!searchMenuIsOpen)
  }

  const handleSortMenuButton = (e) => {
    setSearchMenuIsOpen(false)
    setSortMenuIsOpen(!sortMenuIsOpen)
  }

  return (
    <>
      <div className='flex bg-white shadow'>
        <button className={`flex-1 py-4 ${searchMenuIsOpen ? 'bg-blue-600 text-white' : 'text-gray-700'}`} onClick={handleSearchMenuButton}>
          <div className='items-center mx-auto space-x-2 w-max'>
            <img src={searchMenuIsOpen ? chevronUpImg : chevronDownImg} className='inline mb-1 ml-2' />
            <span>Filtros de búsqueda</span>
          </div>
        </button>
        <div className='my-4 border-l' />
        <button className={`flex-1 py-4 ${sortMenuIsOpen ? 'bg-blue-600 text-white' : 'text-gray-700'}`} onClick={handleSortMenuButton}>
          <div className='items-center mx-auto space-x-2 w-max'>
            <img src={sortMenuIsOpen ? chevronUpImg : chevronDownImg} className='inline mb-1 ml-2' />
            <span>Estado:</span>
            <span className={`${sortMenuIsOpen ? 'text-white' : 'text-blue-600'} font-bold`}>{props.filterOptions.find(option => JSON.stringify(option.state) === JSON.stringify(props.currentFilter)).title}</span>
          </div>
        </button>
      </div>
      {sortMenuIsOpen && (
        <div className='z-10 text-gray-700 divide-y shadow bg-gray-50 divide-solid'>
          {props.filterOptions.map(option => {
            return <a key={option.title} href={option.url} className={`block text-lg pl-5 py-5 ${JSON.stringify(option.state) === JSON.stringify(props.currentFilter) ? 'text-blue-600 font-bold' : 'text-gray-700'}`}>{option.title}</a>
          })}
        </div>
      )}

      {searchMenuIsOpen && (
        <div className='z-10 overflow-auto' style={{ height: 'calc(100vh - 9.5em)' }}>
          <div className='p-4 shadow bg-gray-50 h-max'>
            <MyAdsSearch {...props} />
          </div>
          <div className='h-14' />
        </div>
      )}
    </>
  )
}

export default MyAdsSearchOptions
