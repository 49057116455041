import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const CARS = 1

const HomepageSearch = props => {
  const vehicleTypes = props.vehicleTypes.map(vehicleType => { return { value: vehicleType.id, label: vehicleType.name } })
  const [selectedVehicleType, setSelectedVehicleType] = useState(vehicleTypes[0])

  const [makes, setMakes] = useState([])
  const [selectedMake, setSelectedMake] = useState()

  const [models, setModels] = useState([])
  const [selectedModel, setSelectedModel] = useState()

  const [vehicleSubtypes, setVehicleSubtypes] = useState([])
  const [selectedVehicleSubtype, setSelectedVehicleSubtype] = useState()

  const handleClick = () => {
    window.fbq('track', 'Search')

    window.gtag('event', 'conversion', { send_to: 'AW-306649816/9LUpCJKC69ADENi1nJIB' })
  }

  const handleMakeChange = (option) => {
    setSelectedMake(option)
    setSelectedModel(undefined)
    setModels([])
  }

  const handleModelChange = (option) => {
    setSelectedModel(option)
  }

  const handleVehicleSubtypeChange = (option) => {
    setSelectedVehicleSubtype(option)
    setSelectedMake(undefined)
  }

  const handleVehicleTypeChange = (option) => {
    setSelectedVehicleType(option)
    setSelectedVehicleSubtype(undefined)
    setVehicleSubtypes([])
    setSelectedMake(undefined)
    setMakes([])
    setSelectedModel(undefined)
    setModels([])
  }

  useEffect(() => {
    window.fetch(`/api/v1/vehicle_types/${selectedVehicleType.value}/makes`)
      .then(response => response.json())
      .then(data => {
        const makes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setMakes(makes)
      })
  }, [selectedVehicleType])

  useEffect(() => {
    window.fetch(`/api/v1/vehicle_types/${selectedVehicleType.value}/vehicle_subtypes`)
      .then(response => response.json())
      .then(data => {
        const vehicleSubtypes = data.map(vs => {
          return { label: vs.name, value: vs.id }
        })
        setVehicleSubtypes(vehicleSubtypes)
      })
  }, [selectedVehicleType])

  useEffect(() => {
    if (selectedMake === undefined) { return }

    window.fetch(`/api/v1/makes/${selectedMake.value}/models?vehicle_type=${selectedVehicleType.value}`)
      .then(response => response.json())
      .then(data => {
        const models = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setModels(models)
      })
  }, [selectedMake])

  return (
    <form action='/ads' className='px-8 py-2 md:pt-16 md:px-0'>
      <div className='flex flex-wrap w-full space-x-0 md:flex-nowrap md:space-x-4'>
        <div className='w-full md:w-max'>
          <div className='text-white'>Categoría</div>
          <Select
            name='q[vehicle_type_id_eq]'
            className='block w-full my-1 rounded md:w-72'
            options={vehicleTypes}
            value={selectedVehicleType}
            onChange={handleVehicleTypeChange}
            placeholder='Seleccionar...'
            noOptionsMessage={() => 'No hay opciones'}
          />
        </div>

        {selectedVehicleType.value === CARS
          ? (
            <>
              <div className='w-full md:w-max'>
                <div className='text-white'>Marca</div>
                <Select
                  name='q[make_id_eq]'
                  className='block w-full my-1 rounded md:w-72'
                  options={makes}
                  value={selectedMake || null}
                  onChange={handleMakeChange}
                  placeholder='Seleccionar...'
                  noOptionsMessage={() => 'No hay opciones'}
                />
              </div>

              <div className='w-full md:w-max'>
                <div className='text-white'>Modelo</div>
                <Select
                  name='q[model_id_in][]'
                  className='block w-full my-1 rounded md:w-72'
                  options={models}
                  value={selectedModel || null}
                  onChange={handleModelChange}
                  placeholder='Seleccionar...'
                  noOptionsMessage={() => 'No hay opciones'}
                />
              </div>
            </>
            )
          : (
            <>
              <div className='w-full md:w-max'>
                <div className='text-white'>Tipo</div>
                <Select
                  name='q[vehicle_subtype_id_eq]'
                  className='block w-full my-1 rounded md:w-72'
                  options={vehicleSubtypes}
                  value={selectedVehicleSubtype || null}
                  onChange={handleVehicleSubtypeChange}
                  placeholder='Seleccionar...'
                  noOptionsMessage={() => 'No hay opciones'}
                />
              </div>

              <div className='w-full md:w-max'>
                <div className='text-white'>Marca</div>
                <Select
                  name='q[make_id_eq]'
                  className='block w-full my-1 rounded md:w-72'
                  options={makes}
                  value={selectedMake || null}
                  onChange={handleMakeChange}
                  placeholder='Seleccionar...'
                  noOptionsMessage={() => 'No hay opciones'}
                />
              </div>
            </>
            )}

        <div className='self-end w-full my-3 md:w-max md:my-1'>
          <input type='submit' value='Buscar' className='float-right px-4 py-2 text-white bg-blue-600 rounded' onClick={handleClick} />
        </div>
      </div>
    </form>
  )
}

export default HomepageSearch
