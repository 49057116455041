import React, { useState, useRef } from 'react'

import remove from '../../../../images/remove_image.svg'
import plus from '../../../../images/plus.svg'
import loading from '../../../../images/loading.svg'

const FileUploader = ({ maxFiles, ad, id, setBackDisabled, setDoneUploading }) => {
  const fileRef = useRef()

  const [images, setImages] = useState(ad.images)
  const [pendingImages, setPendingImages] = useState(ad.pendingImages || [])
  const [coverId, setCoverId] = useState(ad.images.some(img => img.id === ad.images_order[0]) ? ad.images_order[0] : images[0]?.id)

  const handleAddClick = (e) => {
    e.preventDefault()
    fileRef.current.click()
  }

  const handleChange = (e) => {
    setDoneUploading(false)

    const formData = new FormData()
    const imgArray = []

    Array.from(e.target.files).slice(0, maxFiles - images.length).forEach(file => {
      imgArray.push({ url: URL.createObjectURL(file) })
      formData.append('ad[images][]', file)
    })

    window.fetch(`/api/v1/publications/${id}/images`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      },
      body: formData
    })
      .then(response => response.json())
      .then(d => {
        setImages((prevImages) => {
          if (!prevImages.length) { setCoverId(d[0]) }
          d.map((id, index) => (imgArray[index].id = id))
          ad.images = prevImages.concat(imgArray)
          return prevImages.concat(imgArray)
        })

        setPendingImages((prevPendingImages) => {
          const newPendingImages = prevPendingImages.slice()
          newPendingImages.splice(pendingImages.length, d.length)

          setDoneUploading(!newPendingImages.some(img => !img.id))
          ad.pendingImages = newPendingImages

          setBackDisabled(false)

          return newPendingImages
        })
      })

    setPendingImages((prevPendingImages) => {
      ad.pendingImages = prevPendingImages.concat(imgArray)
      return prevPendingImages.concat(imgArray)
    })

    setBackDisabled(true)
  }

  const handleImageClick = (e, imgId) => {
    e.preventDefault()
    if (e.target === e.currentTarget) {
      const order = images.map(img => img.id).filter(id => id !== imgId)
      order.unshift(imgId)

      ad.images_order = order

      window.fetch(`/api/v1/publications/${id}`, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
        body: JSON.stringify({ ad: { images_order: order } })
      })

      setCoverId(imgId)
    }
  }

  const removeImage = (imgId, index) => {
    window.fetch(`/api/v1/publications/${id}/images/${imgId}`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      }
    })

    const newImgArray = [...images]
    newImgArray.splice(index, 1)
    ad.images = newImgArray
    setImages(newImgArray)

    if (newImgArray.length === 0) setDoneUploading(false)

    if (coverId === imgId) { setCoverId(newImgArray[0].id) }
  }

  return (
    <>
      <input type='file' ref={fileRef} accept='image/*' multiple onChange={handleChange} className='hidden' />
      <div className='p-1 mx-auto text-center bg-gray-100 border border-gray-200 rounded w-78 md:w-192' onClick={!(images.length || pendingImages.length) ? handleAddClick : undefined}>
        <div className='grid grid-cols-2 md:grid-cols-5'>
          {images.map((img, index) =>
            <div key={index} className='relative flex m-1 overflow-hidden bg-center bg-no-repeat bg-cover border border-gray-200 rounded cursor-pointer w-36 h-36' style={{ backgroundImage: `url("${img.url}")` }} onClick={(e) => handleImageClick(e, img.id)}>
              <>
                <img className='absolute p-1 bg-black bg-opacity-50 rounded-full right-1 top-1' src={remove} onClick={() => removeImage(img.id, index)} />
                {coverId === img.id && (
                  <p className='absolute bottom-0 left-0 w-full py-1 text-xs text-center text-white bg-blue-700'>Portada</p>
                )}
              </>
            </div>
          )}
          {pendingImages.map((img, index) =>
            <div key={index} className='relative flex m-1 overflow-hidden bg-center bg-no-repeat bg-cover border border-gray-200 rounded w-36 h-36' style={{ backgroundImage: `url("${img.url}")` }}>
              <div className='flex w-full h-full'>
                <div className='absolute w-full h-full bg-black opacity-50' />
                <img src={loading} className='w-6 h-6 m-auto animate-spin' />
              </div>
            </div>
          )}
          {images.length + pendingImages.length > 0 && images.length + pendingImages.length < maxFiles &&
            <div className='flex m-1 bg-gray-200 rounded cursor-pointer w-36 h-36' onClick={handleAddClick}>
              <img className='w-16 h-16 m-auto opacity-10' src={plus} />
            </div>}
        </div>
        {!images.length && !pendingImages.length &&
          <>
            <p className='absolute invisible p-10 m-auto text-gray-400 cursor-default md:visible md:relative'>Haga click <span className='underline cursor-pointer'>aquí</span> para subir archivos</p>
            <p className='p-10 m-auto text-gray-400 cursor-default md:invisible md:absolute'>Toque aquí para subir archivos</p>
          </>}
      </div>
    </>
  )
}

export default FileUploader
