import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Form from './form'

const scheduleOptions = [...Array(24).keys()].map(x => ({ value: x, label: `${('0' + x).slice(-2)}:00 hs` }))

const CustomShopForm = props => {
  const processedCustomShop = Object.assign({}, props.customShop)

  const { setValue } = useForm({ defaultValues: processedCustomShop })
  setValue('useUserEmail', !processedCustomShop.email)
  processedCustomShop.useUserEmail = !processedCustomShop.email

  if (props.customShop.id !== null) {
    const newSchedules = {}
    props.customShop.schedules.forEach(schedule => {
      schedule.from_time = scheduleOptions[parseInt(schedule.from_time.split('T')[1].substring(0, 2))]
      schedule.to_time = scheduleOptions[parseInt(schedule.to_time.split('T')[1].substring(0, 2))]
      if (newSchedules[schedule.day] === undefined) {
        newSchedules[schedule.day] = []
      }
      newSchedules[schedule.day].push({ from_time: schedule.from_time, to_time: schedule.to_time, id: schedule.id })
    })
    processedCustomShop.schedules = newSchedules
    processedCustomShop.oldBranches = processedCustomShop.branches
    processedCustomShop.oldSchedules = processedCustomShop.schedules
  }

  const [customShop, setCustomShop] = useState(processedCustomShop)

  return (
    <Form
      customShop={customShop}
      formAuthenticityToken={props.formAuthenticityToken}
      setCustomShop={setCustomShop}
      user={props.models.user}
      provinces={props.models.branch.provinceOptions}
      days={props.models.schedule.dayOptions}
      url={props.url}
    />
  )
}

export default CustomShopForm
