import React, { useState } from 'react'

import chevronDown from '../../images/chevron-down.svg'
import chevronUp from '../../images/chevron-up.svg'
import dunuLogo from '../../images/dunucheck-logo.svg'

const OverlayMenu = props => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const [categoriesMenuIsOpen, setCategoriesMenuIsOpen] = useState(false)
  const [aboutMenuIsOpen, setAboutMenuIsOpen] = useState(false)

  const handleCategoriesMenuButton = (e) => {
    setCategoriesMenuIsOpen(!categoriesMenuIsOpen)
  }

  const handleAboutMenuButton = (e) => {
    setAboutMenuIsOpen(!aboutMenuIsOpen)
  }

  const handleMenuButton = (e) => {
    setMenuIsOpen(!menuIsOpen)
    if (!menuIsOpen) setCategoriesMenuIsOpen(false) && setAboutMenuIsOpen(false)
  }

  return (
    <>
      <button className='h-full px-6 pt-1.5' onClick={handleMenuButton}>
        <img src={require('../../images/menu.svg')} className={`m-auto duration-100 opacity-${menuIsOpen ? 0 : 100}`} />
        <img src={require('../../images/close.svg')} className={`-mt-3.5 m-auto duration-100 opacity-${menuIsOpen ? 100 : 0}`} />
        <span className='text-xs font-bold'>Menú</span>
      </button>
      {menuIsOpen && (
        <div className='fixed inset-0 overflow-auto text-gray-700 bg-white pt-14 -z-50'>
          <div className='mx-4 divide-y divide-black divide-opacity-10 divide-solid'>
            {props.user && (
              <div className='mx-2 my-4'>
                <p className='text-xl font-bold'>¡Hola {props.user.full_name || props.user.email}!</p>
                <a className='block px-4 py-2 mt-5 mb-6 text-lg text-center text-white bg-blue-600 rounded' href={props.sell_link}>Vender vehículo</a>
              </div>
            )}
            {props.user
              ? (
                <div>
                  <div className='mx-10 space-y-6 my-7'>
                    {props.account_links.map(group => group.map(link => (
                      <a key={link.url} className='block text-lg' href={link.url} data-method={link.method}>{link.title}</a>
                    )))}
                  </div>
                </div>
                )
              : (
                <div className='my-4'>
                  <div className='mx-2 space-y-2'>
                    <p className='text-xl font-bold'>¡Hola!</p>
                    <p>Inicie sesión o cree una cuenta para comenzar a vender y crear anuncios personalizados</p>
                  </div>
                  <div className='flex w-full mt-5 mb-6 space-x-3'>
                    <a className='flex-1 block px-2 py-2 text-lg text-center text-blue-600 border border-blue-600 rounded' href={props.sign_in_link}>Iniciar sesión</a>
                    <a className='flex-1 block px-2 py-2 text-lg text-center text-white bg-blue-600 rounded' href={props.sell_link}>Vender Vehículo</a>
                  </div>
                </div>
                )}
            <div>
              <div className='mx-10 my-6 space-y-6'>
                <button className='flex block w-full space-x-3 text-lg' onClick={handleCategoriesMenuButton}>
                  <span>Categorías</span>
                  {categoriesMenuIsOpen && <img className='my-auto' src={chevronUp} />}
                  {!categoriesMenuIsOpen && <img className='my-auto' src={chevronDown} />}
                </button>
                {categoriesMenuIsOpen && (
                  <div className='py-6 space-y-6 bg-gray-50 -mx-14 px-14'>
                    {props.categories_links[0].map(category => {
                      return <a key={category.title} href={category.url} className='block text-lg'>{category.title}</a>
                    })}
                  </div>
                )}
                <a className='block text-lg' href={props.commercial_sellers_link}>Agencias y comercios</a>
                <button className='flex block w-full space-x-3 text-lg' onClick={handleAboutMenuButton}>
                  <span>Sobre nosotros</span>
                  {aboutMenuIsOpen && <img className='my-auto' src={chevronUp} />}
                  {!aboutMenuIsOpen && <img className='my-auto' src={chevronDown} />}
                </button>
                {aboutMenuIsOpen && (
                  <div className='py-6 space-y-6 bg-gray-50 -mx-14 px-14'>
                    {props.about_links[0].map(about => {
                      return <a key={about.title} href={about.url} className='block text-lg'>{about.title}</a>
                    })}
                  </div>
                )}
                <a className='block text-lg' href={props.blog_link}>Blog</a>
                <a href={props.dunu_check_link}><button><img src={dunuLogo} class='block mt-6' /></button></a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OverlayMenu
