import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import Buttons from '../../shared/Buttons'
import Title from '../../shared/Title'

const Step2 = ({ nextStep, prevStep, setUserData, user, userData }) => {
  const { control, handleSubmit, register, setValue, watch } = useForm()

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const confirmButtonRef = useRef()
  const watchUserFullName = watch('fullName]')
  const watchUserPhone = watch('phone')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = data => {
    console.log(data)
    setUserData(Object.assign(userData, data))
    nextStep()
  }

  return (
    <>
      <Transition show={dialogIsOpen}>
        <Dialog static className='fixed inset-0 z-10' initialFocus={confirmButtonRef} open={dialogIsOpen} onClose={() => setDialogIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-700 bg-opacity-60' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0 transform scale-95'
            enterTo='opacity-100 transform scale-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100 transform scale-100'
            leaveTo='opacity-0 transform scale-95'
          >
            <div className='flex h-screen'>
              <div className='relative z-10 mx-4 my-auto text-center bg-white rounded shadow-lg p-7 md:mx-auto md:w-1/3'>
                <Dialog.Title className='text-lg font-bold text-blue-600 md:px-7'>Está a punto de salir de la página de creación de solicitud de DunuCheck.</Dialog.Title>
                <Dialog.Description className='mt-5 text-gray-700'>
                  <p><span className='font-bold'>Deberá hacer el proceso nuevamente.</span></p>
                </Dialog.Description>
                <div className='flex mx-auto space-x-5 md:float-right mt-7'>
                  <button onClick={() => setDialogIsOpen(false)} className='w-1/2 px-4 py-2 border border-gray-500 rounded md:w-max'>Cancelar</button>
                  <a href='/profile' ref={confirmButtonRef} className='block w-1/2 px-4 py-2 text-white bg-blue-600 rounded md:w-max'>Continuar<span className='absolute invisible md:visible md:relative'> y salir</span></a>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title text='Su información de contacto' />
        <div className='container px-8 pt-5 mx-auto md:w-3/5 md:pt-6 md:pb-12'>
          <p className='mb-10 text-sm text-gray-600 md:text-center'>
            <span>Estos datos se solicitan para que podamos contactarlo a fin de confirmar la visita con el vendedor. <br />Si desea cambiar algún dato, puede hacerlo desde </span>
            <button type='button' onClick={() => setDialogIsOpen(true)} className='underline'>{user.customShop?.state === 'active' ? 'su Tienda Virtual' : 'Mi perfil'}.</button>
          </p>

          <div className='flex-wrap pb-4 md:flex md:w-max md:mx-auto'>
            <div>
              <div className={`${!user.phone ? 'md:h-32' : 'md:h-24'} ${!user.fullName ? 'w-full md:w-80' : 'w-max'} my-5 md:mx-10`}>
                <div className={`mb-2 ${!(watchUserFullName || user.fullName) && 'text-red-500'}`}>Nombre completo</div>
                {user.fullName
                  ? (
                    <div className='mt-2 ml-2'>{user.fullName}</div>
                    )
                  : (
                    <>
                      <input type='text' {...register('fullName', { required: 'Es obligatorio' })} className={`block w-full rounded ${!watchUserFullName && 'border-red-500'}`} defaultValue={user.fullName} />
                      {!watchUserFullName && <p className='mt-1 text-sm italic text-red-500'>Es necesario completar este dato para poder publicar</p>}
                    </>
                    )}
              </div>

              <div className='my-5 md:h-24 w-max md:mx-10'>
                <div className='mb-1'>Facebook</div>
                <div className='mt-2 ml-2'>{user.facebookName || '-'}</div>
              </div>
            </div>
            <div>
              <div className={`${!user.phone ? 'w-full md:w-80 md:h-32' : 'md:h-24 w-max'} my-5 md:mx-10`}>
                <div className={`mb-2 ${!(watchUserPhone || user.phone) && 'text-red-500'}`}>Celular de contacto</div>
                {user.phone
                  ? (
                    <div className='mt-2 ml-2'>{user.phone}</div>
                    )
                  : (
                    <>
                      <div className='flex'>
                        <Controller
                          name='phone'
                          control={control}
                          rules={{ required: 'Es obligatorio' }}
                          defaultValue={user.phone}
                          render={({ field }) => (
                            <NumberFormat
                              {...field}
                              className={`block w-full rounded ${!watchUserPhone && 'border-red-500'}`}
                              format='+506 ####-####'
                              onValueChange={v => setValue('phone', v.value)}
                              defaultValue={user.phone}
                            />
                          )}
                        />
                      </div>
                      {!watchUserPhone && <p className='mt-1 text-sm italic text-red-500'>Es necesario completar este dato para poder publicar</p>}
                    </>
                    )}
                <label className='block mt-2'>
                  <input type='checkbox' {...register('hasWhatsapp')} defaultChecked={user.hasWhatsapp} />
                  <span className='ml-2'>Deseo que me contacten por WhatsApp</span>
                </label>
              </div>

              <div className='my-5 md:h-24 w-max md:mx-10'>
                <div className='mb-1'>Email de contacto</div>
                <div className='mt-2 ml-2'>{user.email}</div>
              </div>
            </div>
          </div>
        </div>

        <Buttons
          prevStep={prevStep}
        />
      </form>
    </>
  )
}

export default Step2
