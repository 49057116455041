const FilterOption = (candidate, input) => {
  if (input) { return candidate.label.toLowerCase().includes(input.toLowerCase()) }

  return true
}

const IsValidNewOption = (inputValue, value, options) => {
  return inputValue && !options.map(option => option.label.toLowerCase()).includes(inputValue.toLowerCase())
}

export {
  FilterOption,
  IsValidNewOption
}

export default FilterOption
