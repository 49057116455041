import React, { useState } from 'react'

import PlanSelector from './PlanSelector'

const SubscriptionForm = props => {
  const [selectedPlanId, setSelectedPlanId] = useState(props.customShop.paypalPlanId)

  const handleClick = () => {
    window.fetch('/api/v1/subscription', {
      method: 'PUT',
      body: JSON.stringify({ plan_id: selectedPlanId })
    })
      .then(response => response.json())
      .then(json => {
        const approveLink = json.table.links.find(x => x.table.rel === 'approve')

        window.location = approveLink.table.href
      })
  }

  return (
    <>
      <PlanSelector
        paypalPlan1Id={props.paypal_plan_1_id}
        paypalPlan2Id={props.paypal_plan_2_id}
        paypalPlan3Id={props.paypal_plan_3_id}
        selectedPlanId={selectedPlanId}
        setSelectedPlanId={setSelectedPlanId}
        freeTrial={!props.customShop.freeTrialEndsAt}
      />

      <div className='container mt-10 mb-20 mx-auto text-right space-x-10'>
        <button
          className='px-4 py-2 bg-blue-600 text-white rounded disabled:opacity-50'
          disabled={props.customShop.paypalPlanId === selectedPlanId}
          onClick={handleClick}
        >
          Finalizar
        </button>
      </div>
    </>
  )
}

export default SubscriptionForm
