import React, { useState } from 'react'

import Step1 from './steps/Step1'
import Step2 from './steps/Step2'

const UpsalesFormWizard = props => {
  const processedAd = Object.assign({}, props.ad)

  processedAd.existentUpsales = processedAd.upsales.map(upsale => upsale.upsale_type) || []
  processedAd.upsales = []

  const [ad, setAd] = useState(processedAd)

  const [step, setStep] = useState(1)

  const nextStep = () => { setStep(step + 1) }
  const prevStep = () => { setStep(step - 1) }

  return (
    <>
      <div className='pt-16 pb-2 bg-blue-50 md:py-2'>
        <div className='container mx-auto'>
          <div className='grid grid-cols-1 text-sm text-blue-900 md:grid-cols-3'>
            <div className='absolute invisible md:visible md:relative'>Mis anuncios / Personalizar anuncio</div>
            <div className='text-center'>{props.title} - Paso {step} de 2</div>
          </div>
        </div>
      </div>

      {step === 1 && (
        <Step1
          ad={ad}
          models={props.models}
          nextStep={nextStep}
          setAd={setAd}
          upsalePrices={props.upsale_prices}
          faqPath={props.faqPath}
        />
      )}
      {step === 2 && (
        <Step2
          ad={ad}
          exchangeRate={props.exchangeRate}
          formAuthenticityToken={props.formAuthenticityToken}
          prevStep={prevStep}
          upsalePrices={props.upsale_prices}
          user={props.user}
        />
      )}
    </>
  )
}

export default UpsalesFormWizard
