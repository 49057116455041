import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'
import { FilterOption, IsValidNewOption } from '../../../shared/CustomFilter'

const GenericStep1 = ({ ad, vehicleType }) => {
  const { control, formState: { errors }, getValues, register, setValue, watch } = useFormContext()

  const watchMake = watch('make')

  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [vehicleSubtypes, setVehicleSubtypes] = useState([])

  useEffect(() => {
    window.fetch(`/api/v1/vehicle_types/${vehicleType.id}/makes`)
      .then(response => response.json())
      .then(data => {
        const makes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setMakes(makes)

        if (!ad.make_id && ad.make_other) {
          setValue('make_other', ad.make_other)
          setValue('make', { label: ad.make_other, value: '' })
        }
      })
    window.fetch(`/api/v1/vehicle_types/${vehicleType.id}/vehicle_subtypes`)
      .then(response => response.json())
      .then(data => {
        const vehicleSubtypes = data.map(vs => {
          return { label: vs.name, value: vs.id }
        })
        setVehicleSubtypes(vehicleSubtypes)

        if (!ad.vehicle_subtype_id && ad.vehicle_subtype_other) {
          setValue('vehicle_subtype_other', ad.vehicle_subtype_other)
          setValue('vehicle_subtype', { label: ad.vehicle_subtype_other, value: '' })
        }
      })
  }, [vehicleType.id])

  useEffect(() => {
    if (!watchMake) { return }

    window.fetch(`/api/v1/makes/${watchMake.value}/models`)
      .then(response => response.json())
      .then(data => {
        const models = data.filter(m => m.vehicleType.id === vehicleType.id).map(m => {
          return { label: m.name, value: m.id }
        })
        setModels(models)

        if (!ad.model_id && ad.model_other) {
          setValue('model_other', ad.model_other)
          setValue('model', { label: ad.model_other, value: '' })
        }
      })
  }, [watchMake])

  return (
    <div className='grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-10'>
      <div>
        <span>Marca</span>
        <Controller
          name='make'
          control={control}
          rules={{ required: 'Es obligatorio' }}
          render={({ field }) => (
            <CreatableSelect
              {...field}
              className='block w-full rounded'
              options={makes}
              placeholder='Seleccionar...'
              noOptionsMessage={() => 'No hay opciones'}
              formatCreateLabel={(value) => `Crear "${value}"`}
              filterOption={FilterOption}
              isValidNewOption={IsValidNewOption}
              onCreateOption={s => {
                setValue('make_other', s)
                setValue('make', { value: '', label: s })
              }}
            />
          )}
        />
        {errors.make && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.make.message}</p>}
      </div>

      <div>
        <span>Modelo</span>
        <input type='hidden' {...register('model_other')} />
        <Controller
          name='model'
          control={control}
          rules={{
            validate: {
              required: value => {
                if (getValues('model_other') || value) {
                  return true
                } else {
                  return 'Es obligatorio'
                }
              }
            }
          }}
          render={({ field }) => (
            <CreatableSelect
              {...field}
              className='block w-full rounded'
              options={models}
              placeholder='Seleccionar...'
              noOptionsMessage={() => 'No hay opciones'}
              formatCreateLabel={(value) => `Crear "${value}"`}
              filterOption={FilterOption}
              isValidNewOption={IsValidNewOption}
              onCreateOption={s => {
                setValue('model_other', s)
                setValue('model', { value: '', label: s })
              }}
            />
          )}
        />
        {errors.model && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.model.message}</p>}
      </div>

      <div>
        <span>Estilo</span>
        <Controller
          name='vehicle_subtype'
          control={control}
          rules={{ required: 'Es obligatorio' }}
          render={({ field }) => (
            <CreatableSelect
              {...field}
              className='block w-full rounded'
              options={vehicleSubtypes}
              placeholder='Seleccionar...'
              noOptionsMessage={() => 'No hay opciones'}
              formatCreateLabel={(value) => `Crear "${value}"`}
              filterOption={FilterOption}
              isValidNewOption={IsValidNewOption}
              onCreateOption={s => {
                setValue('vehicle_subtype_other', s)
                setValue('vehicle_subtype', { value: '', label: s })
              }}
            />
          )}
        />
        {errors.vehicle_subtype && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.vehicle_subtype.message}</p>}
      </div>
    </div>
  )
}

export default GenericStep1
