import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const MyAdsSearch = props => {
  const locationOptions = props.locationOptions.map(x => { return { label: x[0], value: x[1] } })

  const [locationIn, setLocationIn] = useState((props.q.branches_province_in || []).map(x => locationOptions.find(y => y.value === x)))
  const [buysEq, setBuysEq] = useState(props.q.buys_eq)
  const [consignmentEq, setConsignmentEq] = useState(props.q.consignment_eq)
  const [financingEq, setFinancingEq] = useState(props.q.financing_eq)
  const [vehiclesWarrantyEq, setVehiclesWarrantyEq] = useState(props.q.selected_vehicles_warranty_or_all_vehicles_warranty_eq)
  const [receivesEq, setReceivesEq] = useState(props.q.receives_eq)

  const handleClick = () => {
    window.fbq('track', 'Search')

    window.gtag('event', 'conversion', { send_to: 'AW-306649816/9LUpCJKC69ADENi1nJIB' })
  }

  const handleCustomShopNameChange = (option) => {
    window.location.assign(`${window.location.protocol}${option.value}`)
  }

  const handleLocationChange = (e) => {
    setLocationIn(e)
  }

  const handleReset = (e) => {
    window.location.replace(`${window.location.protocol}${window.location.pathname}`)
  }

  const [customShops, setCustomShops] = useState([])

  useEffect(() => {
    window.fetch('/api/v1/custom_shops')
      .then(response => response.json())
      .then(data => {
        const customShops = data.map(cs => {
          return { label: cs.name, value: cs.slug }
        })
        setCustomShops(customShops)
      })
  }, [])

  return (
    <form action={props.action} className='md:pr-16 space-y-11 md:space-y-6'>

      <div>
        <label className='block space-y-2'>
          <span className='font-bold text-gray-700'>Agencia o comercio</span>
          <Select
            name='q[custom_shop_name_cont]'
            className='block w-full rounded'
            options={customShops}
            onChange={handleCustomShopNameChange}
            placeholder='Buscar...'
            noOptionsMessage={() => 'No hay opciones'}
          />
        </label>
      </div>

      <div>
        <label className='block space-y-2'>
          <div className='font-bold text-gray-700'>Ubicación</div>
          <Select
            name='q[branches_province_in][]'
            className='block w-full rounded'
            options={locationOptions}
            value={locationIn}
            onChange={handleLocationChange}
            isClearable
            isMulti
            placeholder='Seleccionar...'
            noOptionsMessage={() => 'No hay opciones'}
          />
        </label>
      </div>

      <div className='font-bold text-gray-700'>Servicios</div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[selected_vehicles_warranty_or_all_vehicles_warranty_eq]' value='true' checked={vehiclesWarrantyEq === 'true'} onChange={(e) => setVehiclesWarrantyEq(e.target.checked.toString())} />
              <span className='ml-2 font-bold text-gray-700'>Garantía</span>
            </label>
          </div>
        </label>
      </div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[receives_eq]' value='true' checked={receivesEq === 'true'} onChange={(e) => setReceivesEq(e.target.checked.toString())} />
              <span className='ml-2 font-bold text-gray-700'>Recibe vehículo</span>
            </label>
          </div>
        </label>
      </div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[financing_eq]' value='true' checked={financingEq === 'true'} onChange={(e) => setFinancingEq(e.target.checked.toString())} />
              <span className='ml-2 font-bold text-gray-700'>Financiamiento</span>
            </label>
          </div>
        </label>
      </div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[buys_eq]' value='true' checked={buysEq === 'true'} onChange={(e) => setBuysEq(e.target.checked.toString())} />
              <span className='ml-2 font-bold text-gray-700'>Compra vehículo</span>
            </label>
          </div>
        </label>
      </div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[consignment_eq]' value='true' checked={consignmentEq === 'true'} onChange={(e) => setConsignmentEq(e.target.checked.toString())} />
              <span className='ml-2 font-bold text-gray-700'>Venta por consigna</span>
            </label>
          </div>
        </label>
      </div>

      <div className='grid grid-cols-5 pb-8 md:pb-0 gap-7'>
        <button type='button' className='col-span-3 px-2 py-2 bg-transparent border border-gray-600 rounded' onClick={handleReset}>Limpiar campos</button>
        <input type='submit' value='Buscar' className='col-span-2 px-2 py-2 text-white bg-blue-600 rounded' onClick={handleClick} />
      </div>
    </form>
  )
}

export default MyAdsSearch
