import React, { useState } from 'react'

const Field = ({ formAuthenticityToken, label }) => {
  const [password, setPassword] = useState(null)
  const [passwordConfirmation, setPasswordConfirmation] = useState(null)
  const [showForm, setShowForm] = useState(false)

  if (showForm) {
    return (
      <form action='/profile' method='post' data-remote='true' onSubmit={() => setShowForm(false)}>
        <input type='hidden' name='_method' value='patch' />
        <input type='hidden' name='authenticity_token' value={formAuthenticityToken} />
        <div className='flex items-center'>
          <div className='flex-1 space-y-2'>
            <p className='text-gray-700 text-sm'>{label}</p>
            <input type='password' name='user[password]' value={password} onChange={e => setPassword(e.target.value)} placeholder='Contraseña' className='block rounded' />
            <input type='password' name='user[password_confirmation]' value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} placeholder='Repetir contraseña' className='block rounded' />
            {password !== passwordConfirmation && (
              <div className='text-sm text-red-600'>Las contraseñas no coinciden</div>
            )}
          </div>
          <div className='flex-shrink'>
            <input type='submit' value='Guardar' className='bg-gray-50 md:bg-white md:text-sm text-blue-600' />
          </div>
        </div>
      </form>
    )
  } else {
    return (
      <div className='flex items-center'>
        <div className='flex-1'>
          <p className='text-gray-700 text-sm'>{label}</p>
          <p className='ml-2 text-gray-700'>********</p>
        </div>
        <div className='flex-shrink'>
          <button className='text-sm text-gray-700' onClick={() => setShowForm(true)}>Editar</button>
        </div>
      </div>
    )
  }
}

export default Field
