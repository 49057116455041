import React, { useState } from 'react'
import Select from 'react-select'

const calculateMaxOptions = (value, options, descendingOrder) => {
  if (descendingOrder) {
    return options.slice(0, options.indexOf(options.find(option => option.value === value)) + 1)
  } else {
    return options.slice(options.indexOf(options.find(option => option.value === value)), options.length)
  }
}

const calculateMinOptions = (value, options, descendingOrder) => {
  if (descendingOrder) {
    return options.slice(options.indexOf(options.find(option => option.value === value)), options.length)
  } else {
    return options.slice(0, options.indexOf(options.find(option => option.value === value)) + 1)
  }
}

const RangeSelector = props => {
  const options = props.options.map(option => { return { value: option, label: option > 9999 ? Intl.NumberFormat('es', { maximumSignificantDigits: 3 }).format(option) : option } })
  const [minOptions, setMinOptions] = useState(props.valueLteq ? calculateMinOptions(parseInt(props.valueLteq), options, props.descendingOrder) : options)
  const [maxOptions, setMaxOptions] = useState(props.valueGteq ? calculateMaxOptions(parseInt(props.valueGteq), options, props.descendingOrder) : options)

  const handleMinChange = (option) => {
    if (option != null) {
      props.setValueGteq(option.value)
      setMaxOptions(calculateMaxOptions(option.value, options, props.descendingOrder))
    } else { setMaxOptions(options) }
  }

  const handleMaxChange = (option) => {
    if (option != null) {
      props.setValueLteq(option.value)
      setMinOptions(calculateMinOptions(option.value, options, props.descendingOrder))
    } else { setMinOptions(options) }
  }

  return (
    <div className='space-y-2'>
      <div className='font-bold text-gray-700'>{props.label}</div>
      <div className='w-full flex'>
        <Select
          name={`q[${props.name}_gteq]`}
          className='block w-1/2 rounded'
          isClearable
          options={minOptions}
          onChange={handleMinChange}
          defaultValue={options.find(x => x.value === parseInt(props.valueGteq))}
          placeholder='Desde'
          noOptionsMessage={() => 'No hay opciones'}
        />
        <span className='m-2'>-</span>
        <Select
          name={`q[${props.name}_lteq]`}
          className='block w-1/2 rounded'
          isClearable
          options={maxOptions}
          onChange={handleMaxChange}
          defaultValue={options.find(x => x.value === parseInt(props.valueLteq))}
          placeholder='Hasta'
          noOptionsMessage={() => 'No hay opciones'}
        />
      </div>
    </div>
  )
}

export default RangeSelector
