// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

import 'analytics'

import AdFormWizard from './AdFormWizard'
import AlertBanner from './shared/AlertBanner'
import CheckRequestFormWizard from './CheckRequestFormWizard'
import CtcDialog from './CtcDialog'
import CustomShopForm from './CustomShopForm'
import CustomShopHeader from './CustomShopHeader'
import CustomShopSearch from './CustomShopSearch'
import CustomShopSearchOptions from './CustomShopSearchOptions'
import Dropdown from './Dropdown'
import DuplicateAd from './DuplicateAd'
import EditSubscriptionForm from './SubscriptionForm/EditSubscriptionForm'
import HomepageSearch from './HomepageSearch'
import ImageViewer from './ImageViewer'
import InlineFileUploader from './InlineFileUploader'
import MyAdsSearch from './MyAdsSearch'
import MyAdsSearchOptions from './MyAdsSearchOptions'
import NewSubscriptionForm from './SubscriptionForm/NewSubscriptionForm'
import OverlayMenu from './OverlayMenu'
import ProfileForm from './ProfileForm'
import Schedules from './Schedules'
import Search from './Search'
import SearchOptions from './SearchOptions'
import Sort from './Sort'
import UpsalesFormWizard from './UpsalesFormWizard'
import UserPhones from './UserPhones'

import { mountComponents } from 'mount-react-components'

import Trix from 'trix'

require('trix')
require('@rails/actiontext')

require('stylesheets/application.scss')

require.context('images', true)

Rails.start()
ActiveStorage.start()

Trix.config.blockAttributes.heading1.tagName = 'h2'

mountComponents({
  AdFormWizard,
  AlertBanner,
  CheckRequestFormWizard,
  CtcDialog,
  CustomShopForm,
  CustomShopHeader,
  CustomShopSearch,
  CustomShopSearchOptions,
  Dropdown,
  DuplicateAd,
  EditSubscriptionForm,
  HomepageSearch,
  ImageViewer,
  InlineFileUploader,
  MyAdsSearch,
  MyAdsSearchOptions,
  NewSubscriptionForm,
  OverlayMenu,
  ProfileForm,
  Schedules,
  Search,
  SearchOptions,
  Sort,
  UpsalesFormWizard,
  UserPhones
})
