import React from 'react'
import { useFormContext } from 'react-hook-form'

import Title from '../../../../shared/Title'

const BoatStep2 = ({ boatMaterials }) => {
  const { register, formState: { errors } } = useFormContext()

  return (
    <>
      <Title text='Ficha técnica de su bote' />
      <div className='container grid grid-cols-1 gap-8 px-8 py-4 mx-auto md:w-3/4 md:p-12 md:grid-cols-3 md:gap-10'>
        <div>
          <div className='mb-1'>Año (opcional)</div>
          <select {...register('year')} className='block w-full rounded'>
            <option />
            {[...Array((new Date()).getFullYear() - 1948).keys()].map(x => (new Date()).getFullYear() + 1 - x).map(year => {
              return <option key={year} value={year}>{year}</option>
            })}
          </select>
        </div>

        <div>
          <div className='mb-1'>Tamaño en pies (opcional)</div>
          <input type='number' {...register('boat_length', { min: { value: 0, message: 'Debe ser superior a 0' } })} className='block w-full rounded' />
          {errors.boat_length && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.boat_length.message}</p>}
        </div>

        <div>
          <div className='mb-1'>Capacidad de personas (opcional)</div>
          <input type='number' {...register('capacity', { min: { value: 0, message: 'Debe ser superior a 0' } })} className='block w-full rounded' />
          {errors.capacity && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.capacity.message}</p>}
        </div>

        <div>
          <div className='mb-4'>Material del casco (opcional)</div>
          <div className='flex'>
            <div className='flex-1'>
              <img src={require('../../../../../images/wizard/boat-material.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              <label className='block'>
                <input type='radio' {...register('boat_material_id')} value='' />
                <span className='ml-2'>N/A</span>
              </label>
              {boatMaterials.map(boatMaterial => {
                return (
                  <label key={boatMaterial.id} className='block'>
                    <input type='radio' {...register('boat_material_id')} value={boatMaterial.id} />
                    <span className='ml-2'>{boatMaterial.name}</span>
                  </label>
                )
              })}
            </div>
          </div>
        </div>

        <div>
          <div className='mb-4 space-y-1'>Carreta</div>
          <div className='flex'>
            <div className='flex-1'>
              <img src={require('../../../../../images/wizard/trailer.svg')} />
            </div>
            <div className='flex-1'>
              <label className='block'>
                <input type='radio' {...register('includes_trailer')} value='1' />
                <span className='ml-2'>Incluída</span>
              </label>
              <label className='block'>
                <input type='radio' {...register('includes_trailer')} value='0' />
                <span className='ml-2'>No incluída</span>
              </label>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default BoatStep2
