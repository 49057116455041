import React, { useState } from 'react'
import NumberFormat from 'react-number-format'

const Field = ({ formAuthenticityToken, initialValue, label, name }) => {
  const [value, setValue] = useState(initialValue)
  const [showForm, setShowForm] = useState(false)

  const handleSubmit = (e) => {
    if (!value) {
      e.preventDefault()
      setValue(initialValue)
    } else {
      e.target.form.submit()
      setValue(`+506 ${value.substring(0, 4)}-${value.substring(4, 8)}`)
    }
    setShowForm(false)
  }

  if (showForm) {
    return (
      <form action='/profile' method='post' data-remote='true' onSubmit={() => setShowForm(false)}>
        <input type='hidden' name='_method' value='patch' />
        <input type='hidden' name='authenticity_token' value={formAuthenticityToken} />
        <div className='flex items-center'>
          <div className='flex-1 space-y-2'>
            <p className='text-gray-700 text-sm'>{label}</p>
            <NumberFormat
              className='rounded'
              format='+506 ####-####'
              name={name}
              onValueChange={v => setValue(v.value)}
              value={value}
            />
          </div>
          <div className='flex-shrink'>
            <button className='bg-gray-50 md:bg-white md:text-sm text-blue-600' onClick={handleSubmit}>Guardar</button>
          </div>
        </div>
      </form>
    )
  } else {
    return (
      <div className='flex items-center'>
        <div className='flex-1'>
          <p className='text-gray-700 text-sm'>{label}</p>
          <p className='ml-2 text-gray-700'>{(value && value) || '-'}</p>
        </div>
        <div className='flex-shrink'>
          <button className='text-sm text-gray-700' onClick={() => setShowForm(true)}>Editar</button>
        </div>
      </div>
    )
  }
}

export default Field
