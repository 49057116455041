import React from 'react'

const Sort = props => {
  const handleChange = (e) => {
    window.location = e.target.value
  }

  return (
    <>
      <label className='block space-x-2'>
        <span className='font-bold text-blue-600'>{props.title}</span>
        <select className='rounded' value={props.options.find(x => x.url.includes(props.currentSort?.replace(' ', '+')))?.url} onChange={handleChange}>
          {props.options.map(option => {
            return <option key={option.title} value={option.url}>{option.title}</option>
          })}
        </select>
      </label>
    </>
  )
}

export default Sort
