import React from 'react'
import dunucheck from '../../../images/dunucheck-logo.svg'
import Title from '../../shared/Title'

const Step1 = ({ amount, exchangeRate, nextStep }) => {
  return (
    <form>
      <Title text='DunuCheck' />
      <div className='container px-8 pt-4 mx-auto md:w-3/4 md:p-2'>
        <div className='mx-auto space-y-4 md:w-1/2'>
          <div className='text-center' />
          <p>Con <img src={dunucheck} class='inline w-28 mx-1' /> usted puede solicitar una inspección del auto de su
            interés sin necesidad de salir de su hogar o trabajo. Realizamos una
            inspección objetiva y profesional.
          </p>
          <p>Vea el vehículo de su interés a través de los ojos de nuestros mecánicos y evite sorpresas.</p>
          <div className='text-center'>
            <b>¿Qué incluye la revisión?</b>
            <div className='text-left'>
              <p>- Carrocería y Pintura.</p>
              <p>- Estado de Interior.</p>
              <p>- Mecánica general (Motor y debajo de carrocería).</p>
              <p>- Revisión General de Computadora (Luz Check Engine).</p>
              <p>- Prueba de Manejo.</p>
            </div>
          </div>
          <p>Al final del proceso, usted podrá acceder a un reporte detallado de todos los aspectos inspeccionados, el cual incluye, fotografías de los detalles
            visuales del vehículo.
          </p>
          <p>Le recomendamos leer todos los detalles de este servicio en <a target='_blank' href='/dunu_check' style={{ color: '#2563eb', textDecorationLine: 'underline' }}><strong>www.dunu506.com/dunu_check</strong></a> antes de proceder.</p>
          <p>El costo del informe es de <b>${amount}</b> (tipo de cambio, ₡{exchangeRate}) = <b style={{ color: '#2563eb' }}>₡{Intl.NumberFormat('en').format(amount * exchangeRate)}</b></p>
        </div>
      </div>
      <div className='container mx-auto mt-10 mb-20 space-x-10 text-center md:text-right'>
        <button type='button' className='px-4 py-2 text-white bg-blue-600 rounded disabled:opacity-50' onClick={nextStep}>Siguiente</button>
      </div>
    </form>
  )
}

export default Step1
