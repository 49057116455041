import React, { useState } from 'react'
import CtcLogo from '../../images/ctc.webp'

const CtcDialog = props => {
  const [requestSent, setRequestSent] = useState(false)

  const sendHandler = () => {
    window.fetch(`/my/ads/${props.adId}/ctc_sends`, {
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      }
    }).then((_) => {
      setRequestSent(true)
    })
  }

  return (
    <div className='bg-blue-100 p-2 mb-8 mx-4 md:mx-0 border rounded-md border-blue-700 text-blue-700'>
      <div className='md:flex'>
        <div className='hidden md:block relative rounded-full bg-white my-0.5' style={{ width: '112px', height: '112px', flexShrink: 0 }}>
          <img src={CtcLogo} className='inset-y-0 m-auto absolute' />
        </div>
        {requestSent
          ? (
            <div className='py-2 px-4'>
              <p className='font-bold text-lg'>¡Listo!</p>
              <span>Su solicitud esta siendo procesada. Pronto </span>
              <a className='font-bold' href='https://www.compramostucarrocr.com/' target='_blank' rel='noreferrer'>Compramos Tu Carro </a>
              <span>se pondrá en contacto.</span>
            </div>
            )
          : (
            <div className='py-2 px-4'>
              <p className='font-bold text-lg'>¡Atención!</p>
              <span>Su auto podría calificar para una oferta de compra directa (24hrs o menos) por parte de </span>
              <a className='font-bold' href='https://www.compramostucarrocr.com/' target='_blank' rel='noreferrer'>Compramos Tu Carro</a>
              <span>. ** Aplican restricciones.</span>
              <p>¿Le gustaría enviar su auto como propuesta para una oferta?</p>
            </div>
            )}
        <div className='flex'>
          <div className='block md:hidden' style={{ flexGrow: 1 }}>
            <img src={CtcLogo} className='ml-2 mt-1 h-8' />
          </div>
          {!requestSent &&
            <button className='mr-2 mb-2 px-4 py-2 text-white bg-blue-600 rounded inline-block self-end' onClick={sendHandler}>Enviar</button>}
        </div>
      </div>
    </div>
  )
}

export default CtcDialog
