import React, { useEffect, useState } from 'react'

import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import Step4 from './steps/Step4'
import Step5 from './steps/Step5'
import Step6 from './steps/Step6'

const AdFormWizard = props => {
  const processedAd = Object.assign({}, props.ad)

  processedAd.boat_material_id = props.ad.boat_material_id?.toString()
  processedAd.bicycle_brake_id = props.ad.bicycle_brake_id?.toString()
  processedAd.engine_type_id = props.ad.engine_type_id?.toString()
  processedAd.engine_strokes = props.ad.engine_strokes?.toString()
  processedAd.frame_material_id = props.ad.frame_material_id?.toString()
  processedAd.includes_trailer = props.ad.includes_trailer ? '1' : '0'
  processedAd.number_of_doors = props.ad.number_of_doors?.toString()
  processedAd.vehicle_type_id = props.ad.vehicle_type_id?.toString()

  if (!props.ad.transmission_type) processedAd.transmission_type = ''
  if (!props.ad.engine_type_id) processedAd.engine_type_id = ''
  if (!props.ad.external_color) processedAd.external_color = 'other'
  if (!props.ad.drive_type) processedAd.drive_type = ''
  if (!props.ad.engine_strokes) processedAd.engine_strokes = ''
  if (!props.ad.bicycle_brake_id) processedAd.bicycle_brake_id = ''
  if (!props.ad.frame_gender) processedAd.frame_gender = ''
  if (!props.ad.suspension_travel) processedAd.suspension_travel = ''
  if (!props.ad.boat_material_id) processedAd.boat_material_id = ''
  if (!props.ad.id) {
    processedAd.price_currency = 'CRC'
    processedAd.receives = (props.models.user.customShop?.state === 'active' && props.models.user.customShop?.receives) ? '1' : '0'
  } else {
    processedAd.receives = props.ad.receives ? '1' : '0'
  }

  processedAd.images.map(image => ({ id: image?.id, url: image?.url }))
  processedAd.price = props.ad.price_cents ? props.ad.price_cents / 100 : null
  processedAd.upsales = []
  processedAd.vehicle_feature_ids = props.ad.vehicle_features.map(x => x.id.toString())

  if (props.models.user.customShop?.state === 'active' && !processedAd.branch_id) {
    processedAd.branch_id = props.models.user.customShop.branches[0].id
    processedAd.location = props.models.user.customShop.branches[0].province
  }

  const [ad, setAd] = useState(processedAd)
  const [step, setStep] = useState(1)

  const nextStep = () => { setStep(step + 1) }
  const prevStep = () => { setStep(step - 1) }

  const [finishPath, setFinishPath] = useState(props.url)
  const [newId, setNewId] = useState(ad.id)

  const handleAlert = event => {
    event.preventDefault()
    event.returnValue = null
    return ''
  }
  useEffect(() => {
    if (!processedAd.id) {
      window.addEventListener('beforeunload', handleAlert)
    }
    return () => {
      window.removeEventListener('beforeunload', handleAlert)
    }
  }, [step])

  return (
    <>
      <div className='pt-16 pb-2 bg-blue-50 md:py-2'>
        <div className='container mx-auto'>
          <div className='grid grid-cols-1 text-sm text-blue-900 md:grid-cols-3'>
            <div className='absolute invisible md:visible md:relative'>Inicio / Vender vehículo</div>
            <div className='text-center'>{props.title} - Paso {step} de {props.ad.id && props.ad.state !== 'draft' ? 4 : 6}</div>
          </div>
        </div>
      </div>

      {step === 1 && (
        <Step1
          ad={ad}
          models={props.models}
          nextStep={nextStep}
          setAd={setAd}
          setFinishPath={setFinishPath}
          setNewId={setNewId}
          id={newId || ad.id}
        />
      )}
      {step === 2 && (
        <Step2
          ad={ad}
          models={props.models}
          nextStep={nextStep}
          prevStep={prevStep}
          setAd={setAd}
          id={newId || ad.id}
        />
      )}
      {step === 3 && (
        <Step3
          ad={ad}
          nextStep={nextStep}
          prevStep={prevStep}
          setAd={setAd}
          user={props.models.user}
          editUrl={props.editUrl}
          id={newId || ad.id}
        />
      )}
      {step === 4 && (
        <Step4
          ad={ad}
          nextStep={nextStep}
          prevStep={prevStep}
          setAd={setAd}
          url={finishPath}
          id={newId || ad.id}
        />
      )}
      {step === 5 && (
        <Step5
          ad={ad}
          nextStep={nextStep}
          prevStep={prevStep}
          setAd={setAd}
          upsalePrices={props.models.upsalePrices}
          faqPath={props.faqPath}
        />
      )}
      {step === 6 && (
        <Step6
          ad={ad}
          exchangeRate={props.exchangeRate}
          googleTagId={props.googleTagId}
          id={newId || ad.id}
          prevStep={prevStep}
          upsalePrices={props.models.upsalePrices}
          url={finishPath}
          user={props.models.user}
          handleAlert={handleAlert}
        />
      )}
    </>
  )
}

export default AdFormWizard
