import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import Buttons from '../../../shared/Buttons'
import CarsStep2 from './CarsStep2'
import MotorbikesStep2 from './MotorbikesStep2'
import BicyclesStep2 from './BicyclesStep2'
import IndustrialStep2 from './IndustrialStep2'
import AtvsStep2 from './AtvsStep2'
import BoatStep2 from './NauticalStep2/BoatStep2'
import EngineStep2 from './NauticalStep2/EngineStep2'
import Title from '../../../shared/Title'

const CARS = 1
const MOTORBIKES = 2
const BICYCLES = 3
const INDUSTRIAL = 4
const ATVS = 5
const NAUTICAL = 6

const Step2 = ({ ad, models, nextStep, prevStep, setAd, id }) => {
  const methods = useForm({ defaultValues: ad })

  const selectedBoatSaleType = methods.getValues('boat_sale_type')
  const selectedVehicleType = models.vehicleTypes.find(vehicleType => vehicleType.id === parseInt(methods.getValues('vehicle_type_id')))

  const [vehicleFeatures, setVehicleFeatures] = useState([])

  useEffect(() => {
    window.fetch(`/api/v1/vehicle_types/${selectedVehicleType.id}/vehicle_features`)
      .then(response => response.json())
      .then(data => {
        setVehicleFeatures(data)
      })

    if (!ad.id) { return }

    if (ad.wheel_size_id && !ad.wheel_size) {
      const wheelSize = models.wheelSizes.find(ws => ws.id === ad.wheel_size_id)
      methods.setValue('wheel_size', { label: wheelSize.name, value: wheelSize.id })
    } else if (!ad.make) {
      methods.setValue('wheel_size', { label: ad.wheel_size_other, value: '' })
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const groupedVehicleFeatures = vehicleFeatures.reduce((r, a) => {
    r[a.vehicleFeatureCategory.name] = r[a.vehicleFeatureCategory.name] || []
    r[a.vehicleFeatureCategory.name].push(a)
    return r
  }, Object.create(null))

  const onSubmit = data => {
    setAd(Object.assign(ad, data))

    const jsonData = {
      ad: {
        bicycle_brake_id: ad.bicycle_brake_id,
        boat_length: ad.boat_length,
        boat_material_id: ad.boat_material_id,
        capacity: ad.capacity,
        drive_type: ad.drive_type,
        engine_hours: ad.engine_hours,
        engine_hp: ad.engine_hp,
        engine_strokes: ad.engine_strokes,
        engine_type_id: ad.engine_type_id,
        engine_type_other: ad.engine_type_other,
        engine_year: ad.engine_year,
        external_color: ad.external_color,
        frame_gender: ad.frame_gender,
        frame_material_id: ad.frame_material_id,
        frame_size: ad.frame_size,
        includes_trailer: ad.includes_trailer,
        fuel_type: ad.fuel_type,
        license_plate_last_digit: ad.license_plate_last_digit,
        mileage: ad.mileage,
        mileage_unit: ad.mileage_unit,
        number_of_doors: ad.number_of_doors,
        pending_registration: ad.pending_registration,
        suspension_type: ad.suspension_type,
        suspension_travel: ad.suspension_travel,
        transmission_type: ad.transmission_type,
        vehicle_feature_ids: ad.vehicle_feature_ids,
        wheel_size_id: ad.wheel_size?.value,
        wheel_size_other: ad.wheel_size_other,
        year: ad.year
      }
    }

    if (!ad.boat_sale_type) { jsonData.engine_type_id = ad.engine_type_id }

    window.fetch(`/api/v1/publications/${id}`, {
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      },
      body: JSON.stringify(jsonData)
    })
      .then(nextStep())
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {selectedVehicleType && selectedVehicleType.id === CARS && (
          <CarsStep2
            ad={ad}
          />
        )}
        {selectedVehicleType && selectedVehicleType.id === MOTORBIKES && (
          <MotorbikesStep2
            ad={ad}
          />
        )}
        {selectedVehicleType && selectedVehicleType.id === BICYCLES && (
          <BicyclesStep2
            ad={ad}
            models={models}
          />
        )}
        {selectedVehicleType && selectedVehicleType.id === INDUSTRIAL && (
          <IndustrialStep2
            ad={ad}
          />
        )}
        {selectedVehicleType && selectedVehicleType.id === ATVS && (
          <AtvsStep2
            ad={ad}
          />
        )}
        {selectedVehicleType && selectedVehicleType.id === NAUTICAL &&
        (selectedBoatSaleType === 'boat' || selectedBoatSaleType === 'boat_and_engine') && (
          <BoatStep2
            boatMaterials={models.boatMaterials}
          />
        )}
        {selectedVehicleType && selectedVehicleType.id === NAUTICAL &&
        (selectedBoatSaleType === 'engine' || selectedBoatSaleType === 'boat_and_engine') && (
          <EngineStep2
            ad={ad}
          />
        )}

        {selectedVehicleType && selectedVehicleType.id === CARS && (
          <div className='mt-10'>
            <Title text='Extras que posee su auto' />

            <div className='container grid grid-cols-1 gap-10 px-8 py-6 mx-auto md:w-3/4 md:grid-cols-3'>
              {groupedVehicleFeatures && Object.entries(groupedVehicleFeatures).map(entry => {
                return (
                  <div key={entry[0]} className='space-y-2'>
                    <div className='font-bold'>{entry[0]}</div>
                    {entry[1].map(vehicleFeature => {
                      return (
                        <label key={vehicleFeature.id} className='block'>
                          <input type='checkbox' {...methods.register('vehicle_feature_ids[]')} value={vehicleFeature.id} />
                          <span className='ml-2'>{vehicleFeature.name}</span>
                        </label>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        )}

        {selectedVehicleType && selectedVehicleType.id === ATVS && (
          <div className='mt-10'>
            <Title text='Extras que posee su ATV' />

            <div className='container grid grid-cols-1 gap-2 px-8 py-6 mx-auto md:w-1/2 md:grid-cols-3'>
              {vehicleFeatures.map(vehicleFeature => {
                return (
                  <label key={vehicleFeature.id} className='block'>
                    <input type='checkbox' {...methods.register('vehicle_feature_ids[]')} value={vehicleFeature.id} />
                    <span className='ml-2'>{vehicleFeature.name}</span>
                  </label>
                )
              })}
            </div>
          </div>
        )}

        <Buttons
          prevStep={prevStep}
        />
      </form>
    </FormProvider>
  )
}

export default Step2
