import classNames from 'classnames'
import React from 'react'

const SubscriptionForm = ({ paypalPlan1Id, paypalPlan2Id, paypalPlan3Id, selectedPlanId, setSelectedPlanId, freeTrial }) => {
  const handleClick = value => e => {
    setSelectedPlanId(null)
    setTimeout(function () {
      setSelectedPlanId(value)
    }, 0)
  }

  return (
    <div className='grid grid-cols-1 gap-10 md:grid-cols-3 md:gap-14 mt-9 md:mt-12'>
      <div onClick={handleClick(paypalPlan1Id)}>
        <div className={classNames('p-8 bg-white rounded', { 'border-2 border-blue-600': selectedPlanId === paypalPlan1Id })}>
          <p className='text-3xl font-bold text-blue-600 font-raleway'>Plan 1</p>
          <ul className='px-4 my-6 space-y-4 list-disc'>
            <li>Suba hasta 5 anuncios de vehículos por mes</li>
            <li>Un destacador permanente en todos sus anuncios con el nombre de su negocio</li>
            <li>Tienda virtual en nuestra plataforma con dominio personalizable</li>
            <li>Aprobación automática de todos los anuncios nuevos</li>
          </ul>
          {freeTrial
            ? (
              <>
                <p className='text-3xl font-bold text-blue-600 font-raleway'>Gratis por 6 meses</p>
                <p className='mt-2 text-2xl text-gray-600 font-raleway'>Después $80/mes</p>
              </>
              )
            : (
              <p className='text-3xl font-bold text-blue-600 font-raleway'>$80/mes</p>
              )}
        </div>
      </div>

      <div onClick={handleClick(paypalPlan2Id)}>
        <div className={classNames('p-8 bg-white rounded', { 'border-2 border-blue-600': selectedPlanId === paypalPlan2Id })}>
          <p className='text-3xl font-bold text-blue-600 font-raleway'>Plan 2</p>
          <ul className='px-4 my-6 space-y-4 list-disc'>
            <li>Suba hasta 10 anuncios de vehículos por mes</li>
            <li>Un destacador permanente en todos sus anuncios con el nombre de su negocio</li>
            <li>Tienda virtual en nuestra plataforma con dominio personalizable</li>
            <li>Aprobación automática de todos los anuncios nuevos</li>
          </ul>
          {freeTrial
            ? (
              <>
                <p className='text-3xl font-bold text-blue-600 font-raleway'>Gratis por 6 meses</p>
                <p className='mt-2 text-2xl text-gray-600 font-raleway'>Después $100/mes</p>
              </>
              )
            : (
              <p className='text-3xl font-bold text-blue-600 font-raleway'>$100/mes</p>
              )}
        </div>
      </div>

      <div onClick={handleClick(paypalPlan3Id)}>
        <div className={classNames('p-8 bg-white rounded', { 'border-2 border-blue-600': selectedPlanId === paypalPlan3Id })}>
          <p className='text-3xl font-bold text-blue-600 font-raleway'>Plan 3</p>
          <ul className='px-4 my-6 space-y-4 list-disc'>
            <li>Suba cuantos anuncios desee / ilimitados</li>
            <li>Un destacador permanente en todos sus anuncios con el nombre de su negocio</li>
            <li>Tienda virtual en nuestra plataforma con dominio personalizable</li>
            <li>Aprobación automática de todos los anuncios nuevos</li>
          </ul>
          {freeTrial
            ? (
              <>
                <p className='text-3xl font-bold text-blue-600 font-raleway'>Gratis por 6 meses</p>
                <p className='mt-2 text-2xl text-gray-600 font-raleway'>Después $130/mes</p>
              </>
              )
            : (
              <p className='text-3xl font-bold text-blue-600 font-raleway'>$130/mes</p>
              )}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionForm
