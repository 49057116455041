import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import FilterOption from '../../shared/CustomFilter'

import CheckboxList from '../CheckboxList'
import Section from '../Section'
import RangeInput from '../RangeInput'
import RangeSelector from '../RangeSelector'

const NauticalSearch = props => {
  const boatSaleTypeOptions = props.boatSaleTypeOptions.map(x => { return { label: x[0], value: x[1] } })
  const locationOptions = props.locationOptions.map(x => { return { label: x[0], value: x[1] } })

  const [boatLengthGteq, setBoatLengthGteq] = useState(props.q.boat_length_gteq)
  const [boatLengthLteq, setBoatLengthLteq] = useState(props.q.boat_length_lteq)
  const [boatMaterialIdIn, setBoatMaterialIdIn] = useState(props.q.boat_material_id_in || [])
  const [boatSaleTypeIn, setBoatSaleTypeIn] = useState((props.q.boat_sale_type_in || []).map(x => boatSaleTypeOptions.find(y => y.value === x)))
  const [capacityGteq, setCapacityGteq] = useState(props.q.capacity_gteq)
  const [capacityLteq, setCapacityLteq] = useState(props.q.capacity_lteq)
  const [engineHoursGteq, setEngineHoursGteq] = useState(props.q.engine_hours_gteq)
  const [engineHoursLteq, setEngineHoursLteq] = useState(props.q.engine_hours_lteq)
  const [engineHpGteq, setEngineHpGteq] = useState(props.q.engine_hp_gteq)
  const [engineHpLteq, setEngineHpLteq] = useState(props.q.engine_hp_lteq)
  const [engineMakeId, setEngineMakeId] = useState(props.q.engine_make_id_eq)
  const [engineTypeId, setEngineTypeId] = useState(props.q.engine_type_id_eq)
  const [engineYearGteq, setEngineYearGteq] = useState(props.q.engine_year_gteq)
  const [engineYearLteq, setEngineYearLteq] = useState(props.q.engine_year_lteq)
  const [fuelTypeIn, setFuelTypeIn] = useState(props.q.fuel_type_in || [])
  const [includesTrailerEq, setIncludesTrailerEq] = useState(props.q.includes_trailer_eq)
  const [locationIn, setLocationIn] = useState((props.q.location_in || []).map(x => locationOptions.find(y => y.value === x)))
  const [makeId, setMakeId] = useState(props.q.make_id_eq)
  const [modelIdIn, setModelIdIn] = useState(props.q.model_id_in || [])
  const [ownerTypeEq, setOwnerTypeEq] = useState(props.q.owner_type_eq)
  const [priceGteq, setPriceGteq] = useState(props.q.price_gteq)
  const [priceLteq, setPriceLteq] = useState(props.q.price_lteq)
  const [receivesEq, setReceivesEq] = useState(props.q.receives_eq)
  const [vehicleSubtypeId, setVehicleSubtypeId] = useState(props.q.vehicle_subtype_id_eq)
  const [yearGteq, setYearGteq] = useState(props.q.year_gteq)
  const [yearLteq, setYearLteq] = useState(props.q.year_lteq)

  const handleBoatMaterialIdInChange = (e) => {
    if (e.target.checked) {
      boatMaterialIdIn.push(e.target.value)
      setBoatMaterialIdIn(boatMaterialIdIn)
    } else {
      setBoatMaterialIdIn(boatMaterialIdIn.filter(x => x !== e.target.value))
    }
  }

  const handleEngineMakeChange = (option) => {
    setEngineMakeId(option.value)
  }

  const handleEngineTypeChange = (option) => {
    setEngineTypeId(option?.value)
  }

  const handleFuelTypeInChange = (e) => {
    if (e.target.checked) {
      fuelTypeIn.push(e.target.value)
      setFuelTypeIn(fuelTypeIn)
    } else {
      setFuelTypeIn(fuelTypeIn.filter(x => x !== e.target.value))
    }
  }

  const handleMakeChange = (option) => {
    setMakeId(option?.value)
    setModelIdIn(undefined)
    setModels([])
  }

  const handleModelChange = (option) => {
    setModelIdIn(option?.value)
  }

  const handleBoatSaleTypeChange = (e) => {
    setBoatSaleTypeIn(e)
  }

  const handleLocationChange = (e) => {
    setLocationIn(e)
  }

  const handleOwnerTypeChange = (e) => {
    if (e.target.checked) {
      setOwnerTypeEq(e.target.value)
    } else {
      setOwnerTypeEq(undefined)
    }
  }

  const handleVehicleSubtypeChange = (option) => {
    setVehicleSubtypeId(option?.value)
  }

  const [boatMaterials, setBoatMaterials] = useState([])
  const [engineTypes, setEngineTypes] = useState([])
  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [vehicleSubtypes, setVehicleSubtypes] = useState([])

  useEffect(() => {
    window.fetch('/api/v1/boat_materials')
      .then(response => response.json())
      .then(data => {
        setBoatMaterials(data)
      })
    window.fetch(`/api/v1/vehicle_types/${props.vehicleType.id}/engine_types`)
      .then(response => response.json())
      .then(data => {
        const engineTypes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setEngineTypes(engineTypes)
      })
    window.fetch(`/api/v1/vehicle_types/${props.vehicleType.id}/makes`)
      .then(response => response.json())
      .then(data => {
        const makes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setMakes(makes)
      })
    window.fetch(`/api/v1/vehicle_types/${props.vehicleType.id}/vehicle_subtypes`)
      .then(response => response.json())
      .then(data => {
        const vehicleSubtypes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setVehicleSubtypes(vehicleSubtypes)
      })
  }, [])

  useEffect(() => {
    if (makeId === undefined) { return }

    window.fetch(`/api/v1/makes/${makeId}/models?vehicle_type=${props.vehicleType.id}`)
      .then(response => response.json())
      .then(data => {
        const models = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setModels(models)
        setModelIdIn((props.q.model_id_in || []).map(x => models.find(y => y.value.toString() === x)))
      })
  }, [makeId])

  const boatFilters = (
    <>
      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Tipo</span>
        <Select
          name='q[vehicle_subtype_id_eq]'
          className='block w-full rounded'
          options={vehicleSubtypes}
          value={vehicleSubtypes.find(x => x.value === parseInt(vehicleSubtypeId))}
          onChange={handleVehicleSubtypeChange}
          isClearable
          blurInputOnSelect
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Marca</span>
        <Select
          name='q[make_id_eq]'
          className='block w-full rounded'
          options={makes}
          value={makes.find(x => x.value === parseInt(makeId))}
          onChange={handleMakeChange}
          isClearable
          blurInputOnSelect
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Modelo</span>
        <Select
          name='q[model_id_in][]'
          className='block w-full rounded'
          options={models}
          value={modelIdIn}
          onChange={handleModelChange}
          isClearable
          blurInputOnSelect
          isMulti
          placeholder='Seleccionar...'
          noOptionsMessage={() => makeId ? 'No hay opciones' : 'Seleccione una marca primero'}
          filterOption={FilterOption}
        />
      </div>

      <RangeInput
        label='Tamaño del bote (en pies)'
        name='boat_length'
        valueGteq={boatLengthGteq}
        setValueGteq={setBoatLengthGteq}
        valueLteq={boatLengthLteq}
        setValueLteq={setBoatLengthLteq}
      />

      <RangeSelector
        label='Año'
        name='year'
        options={[...Array((new Date()).getFullYear() - 1948).keys()].map(x => (new Date()).getFullYear() + 1 - x).map(year => year)}
        descendingOrder
        valueGteq={yearGteq}
        setValueGteq={setYearGteq}
        valueLteq={yearLteq}
        setValueLteq={setYearLteq}
      />

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[includes_trailer_eq]' value='true' checked={includesTrailerEq === 'true'} onChange={(e) => setIncludesTrailerEq(e.target.checked.toString())} />
              <span className='ml-2 font-bold text-gray-700'>Incluye carreta</span>
            </label>
          </div>
        </label>
      </div>

      <CheckboxList
        label='Material del casco'
        options={boatMaterials.map(boatMaterial => [boatMaterial.name, boatMaterial.id.toString()])}
        checkedOptions={boatMaterialIdIn}
        onChange={handleBoatMaterialIdInChange}
        name='boat_material_id_in'
      />

      <RangeInput
        label='Capacidad de personas'
        name='capacity'
        valueGteq={capacityGteq}
        setValueGteq={setCapacityGteq}
        valueLteq={capacityLteq}
        setValueLteq={setCapacityLteq}
      />
    </>
  )

  const engineFilters = (
    <>
      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Tipo</span>
        <Select
          name='q[engine_type_id_eq]'
          className='block w-full rounded'
          options={engineTypes}
          value={engineTypes.find(x => x.value === parseInt(engineTypeId))}
          onChange={handleEngineTypeChange}
          isClearable
          blurInputOnSelect
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Marca</span>
        <Select
          name='q[engine_make_id_eq]'
          className='block w-full rounded'
          options={makes}
          value={makes.find(x => x.value === parseInt(engineMakeId))}
          onChange={handleEngineMakeChange}
          isClearable
          blurInputOnSelect
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <RangeInput
        label='Horas de motor'
        name='engine_hours'
        valueGteq={engineHoursGteq}
        setValueGteq={setEngineHoursGteq}
        valueLteq={engineHoursLteq}
        setValueLteq={setEngineHoursLteq}
      />

      <RangeInput
        label='Caballos de fuerza'
        name='engine_hp'
        valueGteq={engineHpGteq}
        setValueGteq={setEngineHpGteq}
        valueLteq={engineHpLteq}
        setValueLteq={setEngineHpLteq}
      />

      <RangeSelector
        label='Año'
        name='engine_year'
        options={[...Array((new Date()).getFullYear() - 1948).keys()].map(x => (new Date()).getFullYear() + 1 - x).map(year => year)}
        descendingOrder
        valueGteq={engineYearGteq}
        setValueGteq={setEngineYearGteq}
        valueLteq={engineYearLteq}
        setValueLteq={setEngineYearLteq}
      />

      <CheckboxList
        label='Tipo de combustible'
        options={props.fuelTypeOptions}
        checkedOptions={fuelTypeIn}
        onChange={handleFuelTypeInChange}
        name='fuel_type_in'
      />
    </>
  )

  return (
    <>
      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Tipo de búsqueda</span>
        <Select
          name='q[boat_sale_type_in][]'
          className='block w-full rounded'
          options={boatSaleTypeOptions}
          value={boatSaleTypeIn}
          onChange={handleBoatSaleTypeChange}
          isClearable
          blurInputOnSelect
          isMulti
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <RangeInput
        label='Precio'
        name='price'
        valueGteq={priceGteq}
        setValueGteq={setPriceGteq}
        valueLteq={priceLteq}
        setValueLteq={setPriceLteq}
      />

      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Ubicación</span>
        <Select
          name='q[location_in][]'
          className='block w-full rounded'
          options={locationOptions}
          value={locationIn}
          onChange={handleLocationChange}
          isClearable
          blurInputOnSelect
          isMulti
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[receives_eq]' value='true' checked={receivesEq === 'true'} onChange={(e) => setReceivesEq(e.target.checked.toString())} />
              <span className='ml-2 font-bold text-gray-700'>Recibe vehículo como pago</span>
            </label>
          </div>
        </label>
      </div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[owner_type_eq]' value='CustomShop' checked={ownerTypeEq === 'CustomShop'} onChange={handleOwnerTypeChange} />
              <span className='ml-2 font-bold text-gray-700'>Sólo agencias y comercios</span>
            </label>
          </div>
        </label>
      </div>

      {boatSaleTypeIn.find(x => x && x.value === 'boat_and_engine')
        ? (
          <>
            <Section
              label='Bote'
              content={boatFilters}
            />
            <Section
              label='Motor'
              content={engineFilters}
            />
          </>
          )
        : (
          <>
            {boatSaleTypeIn.find(x => x && x.value === 'boat') && boatFilters}
            {boatSaleTypeIn.find(x => x && x.value === 'engine') && engineFilters}
          </>
          )}
    </>
  )
}

export default NauticalSearch
