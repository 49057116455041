import React, { useState } from 'react'
import NumberFormat from 'react-number-format'

const RangeInput = props => {
  const [gteq, setGteq] = useState(props.valueGteq)
  const [lteq, setLteq] = useState(props.valueLteq)

  return (
    <div className='space-y-2'>
      <div className='font-bold text-gray-700'>{props.label}</div>
      <div className='w-full flex'>
        <input type='hidden' name={`q[${props.name}_gteq]`} value={gteq} />
        <NumberFormat
          className='block w-1/2 rounded border-gray-300'
          allowNegative={false}
          decimalSeparator='.'
          thousandSeparator=','
          onValueChange={v => setGteq(v.value)}
          defaultValue={gteq}
          placeholder='Desde'
        />
        <span className='m-2'>-</span>
        <input type='hidden' name={`q[${props.name}_lteq]`} value={lteq} />
        <NumberFormat
          className='block w-1/2 rounded border-gray-300'
          allowNegative={false}
          decimalSeparator='.'
          thousandSeparator=','
          onValueChange={v => setLteq(v.value)}
          defaultValue={lteq}
          placeholder='Hasta'
        />
      </div>
    </div>
  )
}

export default RangeInput
