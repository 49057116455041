document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('#contact').forEach(element => {
    element.addEventListener('click', evt => {
      if (element.dataset.analytics) {
        window.fetch('/api/v1/performance_analytics', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: element.dataset.analytics
        })
      }

      window.fbq('trackCustom', 'Contact Seller')
      window.gtag('event', 'conversion', { send_to: 'AW-306649816/z13oCNqMhYcYENi1nJIB' })
    })
  })

  document.querySelectorAll('#sell-vehicle').forEach(element => {
    element.addEventListener('click', evt => {
      window.fbq('trackCustom', 'Start Ad')
      window.gtag('event', 'conversion', { send_to: 'AW-306649816/lZLbCI-C69ADENi1nJIB' })
    })
  })

  document.querySelectorAll('#sign-up').forEach(element => {
    element.addEventListener('click', evt => {
      window.fbq('track', 'Lead')
      window.gtag('event', 'conversion', { send_to: 'AW-306649816/0xIQCJTv04ADENi1nJIB' })
    })
  })
})
