import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const MyAdsSearch = props => {
  const [vehicleTypeId, setVehicleTypeId] = useState(props.q.vehicle_type_id_eq)
  const [makeId, setMakeId] = useState(props.q.make_id_eq)
  const [modelId, setModelId] = useState(props.q.model_id_eq)

  const vehicleTypes = props.vehicleTypes.map(vehicleType => { return { value: vehicleType.id, label: vehicleType.name } })

  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])

  const handleMakeChange = (option) => {
    setMakeId(option?.value)
    setModelId(undefined)
    setModels([])
  }

  const handleModelChange = (option) => {
    setModelId(option?.value)
  }

  const handleReset = (e) => {
    window.location.replace(`${window.location.protocol}${window.location.pathname}`)
  }

  const handleVehicleTypeChange = (option) => {
    setVehicleTypeId(option.value)
    setMakeId(undefined)
    setModelId(undefined)
  }

  useEffect(() => {
    if (vehicleTypeId === undefined) { return }

    window.fetch(`/api/v1/vehicle_types/${vehicleTypeId}/makes`)
      .then(response => response.json())
      .then(data => {
        const makes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setMakes(makes)
      })
  }, [vehicleTypeId])

  useEffect(() => {
    if (makeId === undefined) { return }

    window.fetch(`/api/v1/makes/${makeId}/models?vehicle_type=${vehicleTypeId}`)
      .then(response => response.json())
      .then(data => {
        const models = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setModels(models)
      })
  }, [makeId])

  return (
    <form action='/my/ads' className='md:pr-16 space-y-11 md:space-y-6'>
      {props.q.state_in.map((state, index) =>
        <input key={index} type='hidden' name='q[state_in][]' value={state} />
      )}

      <div>
        <label className='block space-y-2'>
          <span className='font-bold text-gray-700'>Tipo de vehículo</span>
          <Select
            name='q[vehicle_type_id_eq]'
            className='block w-full rounded'
            options={vehicleTypes}
            defaultValue={vehicleTypes.find(x => x.value === parseInt(vehicleTypeId))}
            onChange={handleVehicleTypeChange}
            placeholder='Seleccionar...'
            noOptionsMessage={() => 'No hay opciones'}
          />
        </label>
      </div>

      <div>
        <label className='block space-y-2'>
          <span className='font-bold text-gray-700'>Marca</span>
          <Select
            name='q[make_id_eq]'
            className='block w-full rounded'
            options={makes}
            value={makes.find(x => x.value === parseInt(makeId))}
            onChange={handleMakeChange}
            placeholder='Seleccionar...'
            noOptionsMessage={() => 'No hay opciones'}
          />
        </label>
      </div>

      <div>
        <label className='block space-y-2'>
          <span className='font-bold text-gray-700'>Modelo</span>
          <Select
            name='q[model_id_eq]'
            className='block w-full rounded'
            options={models}
            value={models.find(x => x.value === parseInt(modelId))}
            onChange={handleModelChange}
            placeholder='Seleccionar...'
            noOptionsMessage={() => 'No hay opciones'}
          />
        </label>
      </div>

      <div className='grid grid-cols-5 pb-8 md:pb-0 gap-7'>
        <button type='button' className='col-span-3 px-2 py-2 bg-transparent border border-gray-600 rounded' onClick={handleReset}>Limpiar campos</button>
        <input type='submit' value='Buscar' className='col-span-2 px-2 py-2 text-white bg-blue-600 rounded' />
      </div>
    </form>
  )
}

export default MyAdsSearch
