import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import Select from 'react-select'

const scheduleOptions = [...Array(24).keys()].map(x => ({ value: x, label: `${('0' + x).slice(-2)}:00 hs` }))

const ScheduleSelector = ({ name, disabled }) => {
  const { getValues, setValue } = useFormContext()

  const [fromValue, setFromValue] = useState(getValues(name + '[from_time]'))
  const [toValue, setToValue] = useState(getValues(name + '[to_time]'))

  const handleFromChange = value => {
    setFromValue(value)
    setValue(name + '[from_time]', value)
  }

  const handleToChange = value => {
    setToValue(value)
    setValue(name + '[to_time]', value)
  }

  useEffect(() => {
    if (disabled) {
      setFromValue(null)
      setToValue(null)
    }
  }, [disabled])

  return (
    <div className='flex space-x-5 w-full'>
      <Select
        className='block w-1/2 rounded'
        options={scheduleOptions}
        isDisabled={disabled}
        onChange={handleFromChange}
        value={fromValue}
      />
      <span className='mt-2'>a</span>
      <Select
        className='block w-1/2 rounded'
        options={scheduleOptions}
        isDisabled={disabled}
        onChange={handleToChange}
        value={toValue}
      />
    </div>
  )
}

export default ScheduleSelector
