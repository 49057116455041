import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import Buttons from '../../shared/Buttons'
import Title from '../../shared/Title'

const Step3 = ({ ad, nextStep, prevStep, setAd, user, editUrl, id }) => {
  const { control, formState: { errors }, handleSubmit, register, setValue, watch } = useForm({ defaultValues: ad })

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [branch, setBranch] = useState(user.customShop?.branches.find(branch => branch.id === ad.branch_id))
  const confirmButtonRef = useRef()
  const watchUserFullName = watch('user[full_name]')
  const watchUserPhone = watch('user[phone]')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleBranchChange = (e) => {
    const newBranch = user.customShop.branches.find(b => b.id === parseInt(e.target.value))
    setBranch(newBranch)
    setValue('location', newBranch.province)
  }

  const onSubmit = data => {
    if (user.customShop && user.customShop.state === 'active' && data.branch_id === undefined) { data.branch_id = ad.branch_id }
    setAd(Object.assign(ad, data))

    const jsonData = {
      price_cents: ad.price_cents,
      price_currency: ad.price_currency,
      location: ad.location,
      receives: ad.receives,
      description: ad.description,
      branch_id: ad.branch_id,
      owner_attributes: {
        full_name: ad.user?.full_name,
        phone: ad.user?.phone,
        has_whatsapp: ad.user?.has_whatsapp
      }
    }

    window.fetch(`/api/v1/publications/${id}`, {
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      },
      body: JSON.stringify(jsonData)
    })
      .then(nextStep())
  }

  return (
    <>
      <Transition show={dialogIsOpen}>
        <Dialog static className='fixed inset-0 z-10' initialFocus={confirmButtonRef} open={dialogIsOpen} onClose={() => setDialogIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-700 bg-opacity-60' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0 transform scale-95'
            enterTo='opacity-100 transform scale-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100 transform scale-100'
            leaveTo='opacity-0 transform scale-95'
          >
            <div className='flex h-screen'>
              <div className='relative z-10 mx-4 my-auto text-center bg-white rounded shadow-lg p-7 md:mx-auto md:w-1/3'>
                <Dialog.Title className='text-lg font-bold text-blue-600 md:px-7'>Está a punto de salir de la página de creación de anuncio</Dialog.Title>
                <Dialog.Description className='mt-5 text-gray-700'>
                  <p><span className='font-bold'>Toda la información que ha cargado se perderá</span> y deberá hacer el proceso nuevamente.</p>
                  <p>De todas maneras, puede cambiar sus datos de contacto en otro momento <span className='font-bold'>y se actualizarán en su anuncio.</span></p>
                </Dialog.Description>
                <div className='flex mx-auto space-x-5 md:float-right mt-7'>
                  <button onClick={() => setDialogIsOpen(false)} className='w-1/2 px-4 py-2 border border-gray-500 rounded md:w-max'>Cancelar</button>
                  <a href={editUrl} ref={confirmButtonRef} className='block w-1/2 px-4 py-2 text-white bg-blue-600 rounded md:w-max'>Continuar<span className='absolute invisible md:visible md:relative'> y salir</span></a>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title text='Información de venta' />

        <div className='container px-8 py-4 mx-auto md:w-3/4 md:p-12'>
          <div className='grid grid-cols-1 gap-10 md:grid-cols-3'>
            <div>
              <div className='flex gap-5'>
                <div>
                  <div className='mb-1'>Moneda</div>
                  <select {...register('price_currency')} className='block rounded'>
                    <option key={1} value='CRC'>₡</option>
                    <option key={2} value='USD'>$</option>
                  </select>
                </div>
                <div className='flex-1'>
                  <div className='mb-1'>Precio</div>
                  <Controller
                    name='price'
                    control={control}
                    rules={{ required: 'Es obligatorio', min: { value: 10, message: 'Favor poner monto de venta total esperado' } }}
                    render={({ field: { ref, ...field } }) => (
                      <NumberFormat
                        {...field}
                        getInputRef={ref}
                        className='block w-full rounded'
                        allowNegative={false}
                        decimalSeparator='.'
                        onValueChange={v => setValue('price_cents', v.value * 100)}
                        thousandSeparator=','
                      />
                    )}
                  />
                  <p className={`mt-1 text-sm italic text-${errors.price ? 'red' : 'gray'}-500 md:mb-2`}>{errors.price ? errors.price.message : 'Favor poner monto de venta total esperado'}</p>
                </div>
              </div>
            </div>

            <div>
              <div className='mb-1'>Ubicación del vehículo</div>
              <select {...register('location', { required: 'Es obligatorio' })} className='block w-full rounded'>
                <option />
                {ad.location_options.map(option => {
                  return <option key={option[1]} value={option[1]}>{option[0]}</option>
                })}
              </select>
              {errors.location && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.location.message}</p>}
            </div>

            <div>
              <div className='mb-1'>Recibe vehículos a modo de pago</div>
              <div>
                <label className='inline-flex items-center'>
                  <input type='radio' {...register('receives')} value='1' />
                  <span className='ml-2'>Sí</span>
                </label>
              </div>
              <div>
                <label className='inline-flex items-center'>
                  <input type='radio' {...register('receives')} value='0' />
                  <span className='ml-2'>No</span>
                </label>
              </div>
            </div>

            <label className='md:col-span-3'>
              <div className='mb-1'>Descripción del anuncio</div>
              <textarea {...register('description')} className='block w-full h-64 rounded md:h-32' />
            </label>
          </div>
        </div>

        <Title text='Su información de contacto' />
        <div className='container px-8 pt-5 mx-auto md:w-3/5 md:pt-6 md:pb-12'>
          <p className='mb-10 text-sm text-gray-600 md:text-center'>
            <span>Estos datos se mostrarán en su anuncio para que las personas interesadas en su vehículo lo puedan contactar.<br />Recuerde que esta información será pública. Si desea cambiar algún dato, puede hacerlo desde </span>
            <button type='button' onClick={() => setDialogIsOpen(true)} className='underline'>{user.customShop?.state === 'active' ? 'su Tienda Virtual' : 'Mi perfil'}.</button>
          </p>

          {user.customShop?.state === 'active'
            ? (
              <div className='pb-4 md:flex md:w-max md:mx-auto'>
                <div className='my-5 md:mx-10'>
                  <div className='mb-1'>Sucursal de contacto</div>
                  <select {...register('branch_id', { required: 'Es obligatorio' })} className='block w-full rounded' onChange={handleBranchChange} disabled={user.customShop.branches.length === 1}>
                    {user.customShop.branches.map((branch, index) => {
                      return <option key={branch.id} value={branch.id}>{`Sucursal ${index > 0 ? index + 1 : 'principal'} (${branch.address}, ${user.customShop.branches[0].provinceOptions.find(province => province[1] === branch.province)[0]})`}</option>
                    })}
                  </select>
                  {errors.customShop && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.custom_shop_branch.message}</p>}
                </div>

                <div className='my-5 space-y-4 md:mx-10'>
                  <div className='flex'>
                    <img src={require('../../../images/phone.svg')} />
                    <span className='ml-3'>{(branch?.phone?.phone && branch.phone.phone) || '-'}</span>
                    <span className='ml-1'>{(branch?.phone?.extension && `| Ext. ${branch.phone.extension}`)}</span>
                  </div>

                  <div className='flex space-x-3'>
                    <img src={require('../../../images/location-marker-outline.svg')} />
                    <p>{branch.address}, {user.customShop.branches[0].provinceOptions.find(province => province[1] === branch.province)[0]}</p>
                  </div>

                  <div className='flex space-x-3'>
                    <img src={require('../../../images/mail.svg')} />
                    <span>{user.customShop.email || user.email}</span>
                  </div>
                </div>
              </div>
              )
            : (
              <div className='flex-wrap pb-4 md:flex md:w-max md:mx-auto'>
                <div>
                  <div className={`${!user.phone ? 'md:h-32' : 'md:h-24'} ${!user.fullName ? 'w-full md:w-80' : 'w-max'} my-5 md:mx-10`}>
                    <div className={`mb-2 ${!(watchUserFullName || user.fullName) && 'text-red-500'}`}>Nombre completo</div>
                    {user.fullName
                      ? (
                        <div className='mt-2 ml-2'>{user.fullName}</div>
                        )
                      : (
                        <>
                          <input type='text' {...register('user[full_name]', { required: 'Es obligatorio' })} className={`block w-full rounded ${!watchUserFullName && 'border-red-500'}`} defaultValue={user.fullName} />
                          {!watchUserFullName && <p className='mt-1 text-sm italic text-red-500'>Es necesario completar este dato para poder publicar</p>}
                        </>
                        )}
                  </div>

                  <div className='my-5 md:h-24 w-max md:mx-10'>
                    <div className='mb-1'>Facebook</div>
                    <div className='mt-2 ml-2'>{user.facebookName || '-'}</div>
                  </div>
                </div>
                <div>
                  <div className={`${!user.phone ? 'w-full md:w-80 md:h-32' : 'md:h-24 w-max'} my-5 md:mx-10`}>
                    <div className={`mb-2 ${!(watchUserPhone || user.phone) && 'text-red-500'}`}>Celular de contacto</div>
                    {user.phone
                      ? (
                        <div className='mt-2 ml-2'>{user.phone}</div>
                        )
                      : (
                        <>
                          <div className='flex'>
                            <Controller
                              name='user[phone]'
                              control={control}
                              rules={{ required: 'Es obligatorio' }}
                              defaultValue={user.phone}
                              render={({ field }) => (
                                <NumberFormat
                                  {...field}
                                  className={`block w-full rounded ${!watchUserPhone && 'border-red-500'}`}
                                  format='+506 ####-####'
                                  onValueChange={v => setValue('user[phone]', v.value)}
                                  defaultValue={user.phone}
                                />
                              )}
                            />
                          </div>
                          {!watchUserPhone && <p className='mt-1 text-sm italic text-red-500'>Es necesario completar este dato para poder publicar</p>}
                        </>
                        )}
                    <label className='block mt-2'>
                      <input type='checkbox' {...register('user[has_whatsapp]')} defaultChecked={user.hasWhatsapp} />
                      <span className='ml-2'>Deseo que me contacten por WhatsApp</span>
                    </label>
                  </div>

                  <div className='my-5 md:h-24 w-max md:mx-10'>
                    <div className='mb-1'>Email de contacto</div>
                    <div className='mt-2 ml-2'>{user.email}</div>
                  </div>
                </div>
              </div>
              )}
        </div>

        <Buttons
          prevStep={prevStep}
        />
      </form>
    </>
  )
}

export default Step3
