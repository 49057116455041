import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { FilterOption, IsValidNewOption } from '../../../../shared/CustomFilter'

const EngineStep1 = ({ ad, vehicleType, saleType }) => {
  const { control, formState: { errors }, setValue } = useFormContext()

  const [engineTypes, setEngineTypes] = useState([])
  const [makes, setMakes] = useState([])

  useEffect(() => {
    window.fetch(`/api/v1/vehicle_types/${vehicleType.id}/engine_types`)
      .then(response => response.json())
      .then(data => {
        const engineTypes = data.map(et => {
          return { label: et.name, value: et.id }
        })
        setEngineTypes(engineTypes)
      })
    window.fetch(`/api/v1/vehicle_types/${vehicleType.id}/makes`)
      .then(response => response.json())
      .then(data => {
        const makes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setMakes(makes)

        if (!ad.engine_make_id && !ad.engine_make && ad.engine_make_other) {
          setValue('make_other', ad.engine_make_other)
          setValue('make', { label: ad.engine_make_other, value: '' })
        }
      })
  }, [])

  return (
    <div className='grid grid-cols-1 gap-8 mx-auto md:grid-cols-2 md:gap-16 md:w-2/3'>
      <div>
        <span>Marca {saleType === 'boat_and_engine' && 'de motor'}</span>
        <Controller
          name='engine_make'
          control={control}
          rules={{ required: 'Es obligatorio' }}
          render={({ field }) => (
            <CreatableSelect
              {...field}
              className='block w-full rounded'
              options={makes}
              placeholder='Seleccionar...'
              noOptionsMessage={() => 'No hay opciones'}
              formatCreateLabel={(value) => `Crear "${value}"`}
              filterOption={FilterOption}
              isValidNewOption={IsValidNewOption}
              onCreateOption={s => {
                setValue('engine_make_other', s)
                setValue('engine_make', { value: '', label: s })
              }}
            />
          )}
        />
        {errors.engine_make && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.engine_make.message}</p>}
      </div>

      <div>
        <span>Tipo {saleType === 'boat_and_engine' && 'de motor'}</span>
        <Controller
          name='engine_type'
          control={control}
          rules={{ required: 'Es obligatorio' }}
          render={({ field }) => (
            <Select
              {...field}
              className='block w-full rounded'
              options={engineTypes}
              placeholder='Seleccionar...'
              noOptionsMessage={() => 'No hay opciones'}
              filterOption={FilterOption}
              isValidNewOption={IsValidNewOption}
            />
          )}
        />
        {errors.engine_type && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.engine_type.message}</p>}
      </div>
    </div>
  )
}

export default EngineStep1
