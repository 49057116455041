import React, { useRef, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import Branch from './Branch'
import HiddenFields from './HiddenFields'
import Schedule from './Schedule'
import Title from '../../shared/Title'

const safeSlug = (slug) => {
  return slug.replace(/[^a-z0-9+]+/gi, '-').toLowerCase()
}

const Form = ({ customShop, setCustomShop, user, provinces, days, formAuthenticityToken, url }) => {
  const methods = useForm({ defaultValues: customShop })

  const [autoSlug, setAutoSlug] = useState(customShop.slug === null)
  const [branches, setBranches] = useState(methods.getValues('branches').length ? methods.getValues('branches') : [{}])
  const [descriptionCharCount, setDescriptionCharCount] = useState(0)
  const [useUserEmail, setUseUserEmail] = useState(customShop.useUserEmail)

  if (useUserEmail) {
    methods.setValue('email', user.email)
  }

  const handleNewBranchClick = (e) => {
    setBranches(branches.concat({}))
  }

  const RemoveBranch = (id) => {
    for (let i = id; i < branches.length - 1; i++) {
      methods.setValue(`branches[${i}]`, methods.getValues(`branches[${i + 1}]`))
    }
    methods.unregister(`branches[${branches.length - 1}]`)

    const bs = [...branches]
    bs.splice(-1, 1)
    setBranches(bs)
  }

  const handleNameChange = (e) => {
    if (autoSlug) {
      methods.setValue('slug', safeSlug(e.target.value))
    }
  }

  const handleSlugChange = (e) => {
    setAutoSlug(false)
    methods.setValue('slug', safeSlug(e.target.value))
  }

  const handleUseUserEmailChange = (e) => {
    setUseUserEmail(e.target.checked)
    methods.setValue('email', e.target.checked ? user.email : '')
  }

  const onSubmit = data => {
    setCustomShop(Object.assign(customShop, data))
    // we need to give it time to refresh before submitting :(
    setTimeout(() => { formRef.current.submit() }, 100)
  }

  const formRef = useRef(null)

  return (
    <FormProvider {...methods}>
      <form action={url} method='post' ref={formRef} encType='multipart/form-data' onSubmit={methods.handleSubmit(onSubmit)} className='px-4 pt-16 text-gray-700 md:pt-0'>
        <Title text={(!customShop.id ? 'Cree' : 'Edite') + ' su Tienda Virtual'} />
        <p className='mt-6 md:text-center md:w-3/4 md:mx-auto'>Para crear su Tienda Virtual, primero deberá completar el siguiente formulario. Una vez aprobado por nuestros administradores, podrá proceder a seleccionar el plan de suscripción que mejor se adapte a su negocio. Una vez realizado el pago, su Tienda Virtual se activará automáticamente.</p>

        {customShop.id !== null && (
          <input type='hidden' name='_method' value='patch' />
        )}
        <input type='hidden' name='authenticity_token' value={formAuthenticityToken} />
        <HiddenFields customShop={customShop} />

        <div className='container w-3/4 w-full pt-6 pb-12 mx-auto border-b border-gray-400 md:px-12'>
          <div className='grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-8'>
            <h1 className='text-lg font-bold md:col-span-2'>Información básica</h1>
            <div>
              <div className='w-full space-y-1 md:flex md:space-x-2 md:space-y-0'>
                <span className='my-auto flex-0 md:font-bold'>Nombre del negocio</span>
                <input type='text' {...methods.register('name', { required: 'Es obligatorio' })} className='block w-full rounded md:flex-1' onChange={handleNameChange} />
              </div>
              {methods.formState.errors.name && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{methods.formState.errors.name.message}</p>}
            </div>

            <div>
              <div className='w-full space-y-1 md:flex md:space-x-2 md:space-y-0'>
                <span className='my-auto flex-0 md:font-bold'>Sitio web</span>
                <input type='text' {...methods.register('website', { pattern: /^((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi })} className='block w-full rounded md:flex-1' />
              </div>
              {methods.formState.errors.website && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>Es inválido</p>}
            </div>

            {!customShop.id
              ? (
                <div>
                  <div className='w-full space-y-1 md:flex md:space-x-2 md:space-y-0'>
                    <span className='mt-2 md:font-bold'>URL</span>
                    <div className='flex w-full space-x-2'>
                      <span className='mt-2 text-gray-500 flex-0'>https://dunu506.com/</span>
                      <input
                        type='text'
                        className='block w-full rounded md:flex-1'
                        {...methods.register('slug', {
                          required: 'Es obligatorio',
                          validate: async (v) => {
                            const response = await window.fetch(`/api/v1/custom_shops/${v}`)
                            return response.status === 404
                          }
                        })}
                        onChange={handleSlugChange}
                      />
                    </div>
                  </div>
                  {methods.formState.errors.slug && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{methods.formState.errors.slug.message || 'Ya está en uso'}</p>}
                </div>
                )
              : (
                <div className='w-full space-x-2 space-y-1 md:space-y-0'>
                  <span className='mt-2 md:font-bold'>URL</span>
                  <span className='mt-2 text-gray-500 flex-0'>https://dunu506.com/{customShop.slug}</span>
                </div>
                )}

            <div>
              <div className='w-full space-y-1 md:flex md:space-x-2 md:space-y-0'>
                <span className='my-auto flex-0 md:font-bold'>Email</span>
                <input type='text' className='block w-full rounded md:flex-1 disabled:opacity-50' {...methods.register('email', { required: 'Es obligatorio' })} disabled={useUserEmail} />
                <div className='flex'>
                  <label className='my-auto'>
                    <input type='checkbox' {...methods.register('useUserEmail')} onChange={handleUseUserEmailChange} />
                    <span className='ml-2'>Usar email ya registrado</span>
                  </label>
                </div>
              </div>
              {methods.formState.errors.email && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{methods.formState.errors.email.message}</p>}
            </div>
          </div>
        </div>

        {!customShop.id && (
          <div className='container w-3/4 w-full py-12 mx-auto border-b border-gray-400 md:p-12'>
            <div className='grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-8'>
              <div className='md:col-span-2'>
                <h1 className='text-lg font-bold'>Información legal</h1>
                <p className='mt-2'>Los datos de esta sección son para uso interno solamente. No serán publicados ni compartidos. Solicitamos estos datos para verificar que quien está creando la tienda virtual es realmente el dueño (o persona autorizada) del negocio en cuestión. Si tiene dudas no dude en contactarnos.</p>
              </div>
              <div>
                <div className='w-full space-y-1 md:flex md:space-x-2 md:space-y-0'>
                  <span className='my-auto flex-0 md:font-bold'>Cédula Jurídica</span>
                  <input type='text' {...methods.register('company_id', { required: 'Es obligatorio' })} className='block w-full rounded md:flex-1' />
                </div>
                {methods.formState.errors.company_id && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{methods.formState.errors.company_id.message}</p>}
              </div>
              <div>
                <div className='w-full space-y-1 md:flex md:space-x-2 md:space-y-0'>
                  <span className='my-auto flex-0 md:font-bold'>Nombre de Sociedad</span>
                  <input type='text' {...methods.register('company_name', { required: 'Es obligatorio' })} className='block w-full rounded md:flex-1' />
                </div>
                {methods.formState.errors.company_name && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{methods.formState.errors.company_name.message}</p>}
              </div>
              <div>
                <div className='w-full space-y-1 md:flex md:space-x-2 md:space-y-0'>
                  <span className='my-auto flex-0 md:font-bold'>Nombre del representante legal</span>
                  <input type='text' {...methods.register('legal_representative_name', { required: 'Es obligatorio' })} className='block w-full rounded md:flex-1' />
                </div>
                {methods.formState.errors.legal_representative_name && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{methods.formState.errors.legal_representative_name.message}</p>}
              </div>
              <div className='md:col-span-2'>
                <div>
                  <p className='flex-0 md:font-bold'>Documentación</p>
                  <div className='mt-2 space-x-0 space-y-4 md:flex md:space-y-0 md:space-x-6'>
                    <p className='flex-1'>Cargue uno o más documentos que validen su identidad como propietario (o autorizado) de dicho negocio (ejemplos: recibo de agua/luz/teléfono, copia de cédula de identidad de representante legal, copia de actas, carta autenticada por notario)</p>
                    <div className='flex'>
                      <input type='file' {...methods.register('custom_shop[documents][]', { required: 'Es obligatorio' })} multiple accept='image/png, image/jpeg, .pdf' className='my-auto' />
                    </div>
                  </div>
                </div>
                {methods.formState.errors.custom_shop?.documents && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{methods.formState.errors.custom_shop.documents.message}</p>}
              </div>
            </div>
          </div>
        )}

        <div className='container w-3/4 w-full py-12 mx-auto border-b border-gray-400 md:p-12'>
          <h1 className='text-lg font-bold'>Datos de contacto</h1>
          {branches.map((branch, index) => (
            <Branch
              key={index}
              id={index}
              provinces={provinces}
              onRemove={RemoveBranch}
            />
          ))}
          <input type='button' value='Añadir otra sucursal' className='px-4 py-2 text-white bg-blue-600 rounded' onClick={handleNewBranchClick} />
        </div>

        <div className='container w-3/4 w-full py-12 mx-auto border-b border-gray-400 md:p-12'>
          <h1 className='text-lg font-bold'>Horarios de atención</h1>
          <div className='divide-y divide-gray-300'>
            {days.map((day, key) => (
              <Schedule
                key={key}
                day={day}
                customShop={customShop}
              />
            ))}
          </div>
        </div>

        <div className='container w-3/4 w-full py-12 mx-auto border-b border-gray-400 md:p-12'>
          <h1 className='mb-6 text-lg font-bold'>Sobre su negocio</h1>
          <span>Escriba una breve descripción</span>
          <span className='float-right mt-2 text-xs'>{descriptionCharCount}/1000</span>
          <textarea className='block w-full h-40 mt-1 rounded' {...methods.register('about_us')} onChange={(e) => setDescriptionCharCount(e.target.value.length)} maxLength='1000' />
        </div>

        <div className='container w-3/4 w-full py-12 mx-auto md:p-12'>
          <h1 className='text-lg font-bold'>Servicios</h1>
          <div className='mt-6 space-y-6 md:flex md:space-y-0'>
            <div className='space-y-4 md:w-1/3'>
              <label className='block flex-0'>
                <input type='checkbox' {...methods.register('all_vehicles_warranty')} />
                <span className='ml-2'>Garantía en todos los vehículos</span>
              </label>
              <label className='block flex-0'>
                <input type='checkbox' {...methods.register('selected_vehicles_warranty')} />
                <span className='ml-2'>Garantía en vehículos selectos</span>
              </label>
              <label className='block flex-0'>
                <input type='checkbox' {...methods.register('receives')} />
                <span className='ml-2'>Recibimos vehículos como parte de pago</span>
              </label>
              <label className='block flex-0'>
                <input type='checkbox' {...methods.register('financing')} />
                <span className='ml-2'>Ofrecemos financiamiento</span>
              </label>
              <label className='block flex-0'>
                <input type='checkbox' {...methods.register('buys')} />
                <span className='ml-2'>Compramos su vehículo usado</span>
              </label>
              <label className='block flex-0'>
                <input type='checkbox' {...methods.register('consignment')} />
                <span className='ml-2'>Venda su vehículo con nosotros (consigna)</span>
              </label>
            </div>

            <div className='md:w-2/3'>
              <span className='block mb-2 font-bold md:mb-0'>Beneficios adicionales que ofrece su negocio (opcional)</span>
              <span>Cada renglón separado por un Enter se verá como un ítem individual.</span>
              <textarea className='block w-full h-48 mt-2 rounded' {...methods.register('additional_benefits')} />
            </div>
          </div>
        </div>

        <div className='container pb-12 mx-auto space-x-10 md:pr-12 md:pt-8 md:text-right md:pb-20'>
          <input type='submit' value={!customShop.id ? 'Crear Tienda Virtual' : 'Guardar'} className='w-full px-4 py-2 text-white bg-blue-600 rounded disabled:opacity-50 md:w-max' />
        </div>
      </form>
    </FormProvider>
  )
}

export default Form
