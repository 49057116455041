import React from 'react'

const Buttons = props => {
  const handlePrevStep = props.prevStep
  const removeAlert = () => { window.removeEventListener('beforeunload', props.handleAlert) }

  return (
    <div className='container mx-auto mt-10 mb-20 space-x-10 text-center md:text-right'>
      {!props.disabled && props.totalPrice >= 0 && (
        <span className='block p-2 mx-4 my-12 text-gray-700 border border-blue-600 rounded md:m-0 md:p-5 md:inline'>
          <span>Precio final: </span><span className='font-bold'>${props.totalPrice}</span>
        </span>
      )}
      {props.prevStep && (
        <button type='button' onClick={handlePrevStep} className='px-4 py-2 border border-gray-500 rounded disabled:opacity-50' disabled={props.backDisabled}>Anterior</button>
      )}
      <input type='submit' onClick={removeAlert} value={props.finish ? 'Finalizar' : 'Siguiente'} className='px-4 py-2 text-white bg-blue-600 rounded disabled:opacity-50' disabled={props.disabled} />
    </div>
  )
}

export default Buttons
