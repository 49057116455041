import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import FilterOption from '../../shared/CustomFilter'

import CheckboxList from '../CheckboxList'
import RangeInput from '../RangeInput'
import RangeSelector from '../RangeSelector'

const IndustrialSearch = props => {
  const locationOptions = props.locationOptions.map(x => { return { label: x[0], value: x[1] } })

  const [engineHoursGteq, setEngineHoursGteq] = useState(props.q.engine_hours_gteq)
  const [engineHoursLteq, setEngineHoursLteq] = useState(props.q.engine_hours_lteq)
  const [fuelTypeIn, setFuelTypeIn] = useState(props.q.fuel_type_in || [])
  const [locationIn, setLocationIn] = useState((props.q.location_in || []).map(x => locationOptions.find(y => y.value === x)))
  const [makeId, setMakeId] = useState(props.q.make_id_eq)
  const [mileageGteq, setMileageGteq] = useState(props.q.mileage_gteq)
  const [mileageLteq, setMileageLteq] = useState(props.q.mileage_lteq)
  const [modelIdIn, setModelIdIn] = useState(props.q.model_id_in || [])
  const [ownerTypeEq, setOwnerTypeEq] = useState(props.q.owner_type_eq)
  const [priceGteq, setPriceGteq] = useState(props.q.price_gteq)
  const [priceLteq, setPriceLteq] = useState(props.q.price_lteq)
  const [receivesEq, setReceivesEq] = useState(props.q.receives_eq)
  const [transmissionTypeIn, setTransmissionTypeIn] = useState(props.q.transmission_type_in || [])
  const [vehicleSubtypeId, setVehicleSubtypeId] = useState(props.q.vehicle_subtype_id_eq)
  const [yearGteq, setYearGteq] = useState(props.q.year_gteq)
  const [yearLteq, setYearLteq] = useState(props.q.year_lteq)

  const handleFuelTypeInChange = (e) => {
    if (e.target.checked) {
      fuelTypeIn.push(e.target.value)
      setFuelTypeIn(fuelTypeIn)
    } else {
      setFuelTypeIn(fuelTypeIn.filter(x => x !== e.target.value))
    }
  }

  const handleMakeChange = (option) => {
    setMakeId(option?.value)
    setModelIdIn(undefined)
    setModels([])
  }

  const handleModelChange = (option) => {
    setModelIdIn(option?.value)
  }

  const handleLocationChange = (e) => {
    setLocationIn(e)
  }

  const handleOwnerTypeChange = (e) => {
    if (e.target.checked) {
      setOwnerTypeEq(e.target.value)
    } else {
      setOwnerTypeEq(undefined)
    }
  }

  const handleTransmissionTypeInChange = (e) => {
    if (e.target.checked) {
      transmissionTypeIn.push(e.target.value)
      setTransmissionTypeIn(transmissionTypeIn)
    } else {
      setTransmissionTypeIn(transmissionTypeIn.filter(x => x !== e.target.value))
    }
  }

  const handleVehicleSubtypeChange = (option) => {
    setVehicleSubtypeId(option?.value)
  }

  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [vehicleSubtypes, setVehicleSubtypes] = useState([])

  useEffect(() => {
    window.fetch(`/api/v1/vehicle_types/${props.vehicleType.id}/makes`)
      .then(response => response.json())
      .then(data => {
        const makes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setMakes(makes)
      })
    window.fetch(`/api/v1/vehicle_types/${props.vehicleType.id}/vehicle_subtypes`)
      .then(response => response.json())
      .then(data => {
        const vehicleSubtypes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setVehicleSubtypes(vehicleSubtypes)
      })
  }, [])

  useEffect(() => {
    if (makeId === undefined) { return }

    window.fetch(`/api/v1/makes/${makeId}/models?vehicle_type=${props.vehicleType.id}`)
      .then(response => response.json())
      .then(data => {
        const models = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setModels(models)
        setModelIdIn((props.q.model_id_in || []).map(x => models.find(y => y.value.toString() === x)))
      })
  }, [makeId])

  return (
    <>
      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Tipo</span>
        <Select
          name='q[vehicle_subtype_id_eq]'
          className='block w-full rounded'
          options={vehicleSubtypes}
          value={vehicleSubtypes.find(x => x.value === parseInt(vehicleSubtypeId))}
          onChange={handleVehicleSubtypeChange}
          isClearable
          blurInputOnSelect
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Marca</span>
        <Select
          name='q[make_id_eq]'
          className='block w-full rounded'
          options={makes}
          value={makes.find(x => x.value === parseInt(makeId))}
          onChange={handleMakeChange}
          isClearable
          blurInputOnSelect
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Modelo</span>
        <Select
          name='q[model_id_in][]'
          className='block w-full rounded'
          options={models}
          value={modelIdIn}
          onChange={handleModelChange}
          isClearable
          blurInputOnSelect
          isMulti
          placeholder='Seleccionar...'
          noOptionsMessage={() => makeId ? 'No hay opciones' : 'Seleccione una marca primero'}
          filterOption={FilterOption}
        />
      </div>

      <RangeInput
        label='Kilómetros'
        name='mileage'
        valueGteq={mileageGteq}
        setValueGteq={setMileageGteq}
        valueLteq={mileageLteq}
        setValueLteq={setMileageLteq}
      />

      <RangeSelector
        label='Año'
        name='year'
        options={[...Array((new Date()).getFullYear() - 1948).keys()].map(x => (new Date()).getFullYear() + 1 - x).map(year => year)}
        descendingOrder
        valueGteq={yearGteq}
        setValueGteq={setYearGteq}
        valueLteq={yearLteq}
        setValueLteq={setYearLteq}
      />

      <RangeInput
        label='Horas de motor'
        name='engine_hours'
        valueGteq={engineHoursGteq}
        setValueGteq={setEngineHoursGteq}
        valueLteq={engineHoursLteq}
        setValueLteq={setEngineHoursLteq}
      />

      <RangeInput
        label='Precio'
        name='price'
        valueGteq={priceGteq}
        setValueGteq={setPriceGteq}
        valueLteq={priceLteq}
        setValueLteq={setPriceLteq}
      />

      <div className='block space-y-2'>
        <div className='font-bold text-gray-700'>Ubicación</div>
        <Select
          name='q[location_in][]'
          className='block w-full rounded'
          options={locationOptions}
          value={locationIn}
          onChange={handleLocationChange}
          isClearable
          blurInputOnSelect
          isMulti
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[receives_eq]' value='true' checked={receivesEq === 'true'} onChange={(e) => setReceivesEq(e.target.checked.toString())} />
              <span className='ml-2 font-bold text-gray-700'>Recibe vehículo como pago</span>
            </label>
          </div>
        </label>
      </div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[owner_type_eq]' value='CustomShop' checked={ownerTypeEq === 'CustomShop'} onChange={handleOwnerTypeChange} />
              <span className='ml-2 font-bold text-gray-700'>Sólo agencias y comercios</span>
            </label>
          </div>
        </label>
      </div>

      <CheckboxList
        label='Tipo de transmisión'
        options={props.transmissionTypeOptions}
        checkedOptions={transmissionTypeIn}
        onChange={handleTransmissionTypeInChange}
        name='transmission_type_in'
      />

      <CheckboxList
        label='Tipo de combustible'
        options={props.fuelTypeOptions}
        checkedOptions={fuelTypeIn}
        onChange={handleFuelTypeInChange}
        name='fuel_type_in'
      />
    </>
  )
}

export default IndustrialSearch
