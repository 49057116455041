import React, { useState } from 'react'
import classNames from 'classnames'

import chevronDown from '../../images/chevron-down.svg'
import chevronUp from '../../images/chevron-up.svg'

const CheckboxList = props => {
  const [showList, setShowList] = useState(props.checkedOptions.length > 0)

  return (
    <>
      <label className='block space-y-5 md:space-y-3'>
        <button type='button' className='flex w-full items-center border-b border-gray-400' onClick={() => setShowList(!showList)}>
          <div className='w-full font-bold text-left text-gray-700'>{props.label}</div>
          {showList && <img src={chevronUp} />}
          {!showList && <img src={chevronDown} />}
        </button>
        {props.options.map(option => {
          return (
            <div key={option[1]} className={classNames({ hidden: !showList })}>
              <label className='inline-flex items-center'>
                <input className='form-checkbox' type='checkbox' name={`q[${props.name}][]`} value={option[1]} checked={props.checkedOptions.find(x => x === option[1])} onChange={props.onChange} />
                {props.withColorIcons === true && <img className='ml-2' src={require(`../../images/colors/${option[1]}.svg`)} />}
                <span className='ml-2'>{option[0]}</span>
              </label>
            </div>
          )
        })}
      </label>
    </>
  )
}

export default CheckboxList
