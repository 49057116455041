import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import FilterOption from '../../shared/CustomFilter'

import CheckboxList from '../CheckboxList'
import RangeInput from '../RangeInput'

const BicyclesSearch = props => {
  const locationOptions = props.locationOptions.map(x => { return { label: x[0], value: x[1] } })

  const [brakeTypeIdIn, setBrakeTypeIdIn] = useState(props.q.bicycle_brake_id_in || [])
  const [frameGenderIn, setFrameGenderIn] = useState(props.q.frame_gender_in || [])
  const [frameMaterialIdIn, setFrameMaterialIdIn] = useState(props.q.frame_material_id_in || [])
  const [frameSizeIn, setFrameSizeIn] = useState(props.q.frame_size_in || [])
  const [locationIn, setLocationIn] = useState((props.q.location_in || []).map(x => locationOptions.find(y => y.value === x)))
  const [makeId, setMakeId] = useState(props.q.make_id_eq)
  const [ownerTypeEq, setOwnerTypeEq] = useState(props.q.owner_type_eq)
  const [priceGteq, setPriceGteq] = useState(props.q.price_gteq)
  const [priceLteq, setPriceLteq] = useState(props.q.price_lteq)
  const [receivesEq, setReceivesEq] = useState(props.q.receives_eq)
  const [suspensionTravelIn, setSuspesionTravelIn] = useState(props.q.suspension_travel_in || [])
  const [suspensionTypeIn, setSuspesionTypeIn] = useState(props.q.suspension_type_in || [])
  const [vehicleSubtypeId, setVehicleSubtypeId] = useState(props.q.vehicle_subtype_id_eq)
  const [wheelSizeIdIn, setWheelSizeIdIn] = useState(props.q.wheel_size_id_in || [])

  const handleBrakeTypeIdInChange = (e) => {
    if (e.target.checked) {
      brakeTypeIdIn.push(e.target.value)
      setBrakeTypeIdIn(brakeTypeIdIn)
    } else {
      setBrakeTypeIdIn(brakeTypeIdIn.filter(x => x !== e.target.value))
    }
  }

  const handleFrameGenderInChange = (e) => {
    if (e.target.checked) {
      frameGenderIn.push(e.target.value)
      setFrameGenderIn(frameGenderIn)
    } else {
      setFrameGenderIn(frameGenderIn.filter(x => x !== e.target.value))
    }
  }

  const handleFrameMaterialIdInChange = (e) => {
    if (e.target.checked) {
      frameMaterialIdIn.push(e.target.value)
      setFrameMaterialIdIn(frameMaterialIdIn)
    } else {
      setFrameMaterialIdIn(frameMaterialIdIn.filter(x => x !== e.target.value))
    }
  }

  const handleFrameSizeInChange = (e) => {
    if (e.target.checked) {
      frameSizeIn.push(e.target.value)
      setFrameSizeIn(frameSizeIn)
    } else {
      setFrameSizeIn(frameSizeIn.filter(x => x !== e.target.value))
    }
  }

  const handleMakeChange = (option) => {
    setMakeId(option?.value)
  }

  const handleLocationChange = (e) => {
    setLocationIn(e)
  }

  const handleOwnerTypeChange = (e) => {
    if (e.target.checked) {
      setOwnerTypeEq(e.target.value)
    } else {
      setOwnerTypeEq(undefined)
    }
  }

  const handleSuspensionTravelInChange = (e) => {
    if (e.target.checked) {
      suspensionTravelIn.push(e.target.value)
      setSuspesionTravelIn(suspensionTravelIn)
    } else {
      setSuspesionTravelIn(suspensionTravelIn.filter(x => x !== e.target.value))
    }
  }

  const handleSuspensionTypeInChange = (e) => {
    if (e.target.checked) {
      suspensionTypeIn.push(e.target.value)
      setSuspesionTypeIn(suspensionTypeIn)
    } else {
      setSuspesionTypeIn(suspensionTypeIn.filter(x => x !== e.target.value))
    }
  }

  const handleVehicleSubtypeChange = (option) => {
    setVehicleSubtypeId(option?.value)
  }

  const handleWheelSizeIdInChange = (e) => {
    if (e.target.checked) {
      wheelSizeIdIn.push(e.target.value)
      setWheelSizeIdIn(wheelSizeIdIn)
    } else {
      setWheelSizeIdIn(wheelSizeIdIn.filter(x => x !== e.target.value))
    }
  }

  const [bicycleBrakes, setBicycleBrakes] = useState([])
  const [frameMaterials, setFrameMaterials] = useState([])
  const [makes, setMakes] = useState([])
  const [vehicleSubtypes, setVehicleSubtypes] = useState([])
  const [wheelSizes, setWheelSizes] = useState([])

  useEffect(() => {
    window.fetch('/api/v1/bicycle_brakes')
      .then(response => response.json())
      .then(data => {
        setBicycleBrakes(data)
      })
    window.fetch('/api/v1/frame_materials')
      .then(response => response.json())
      .then(data => {
        setFrameMaterials(data)
      })
    window.fetch(`/api/v1/vehicle_types/${props.vehicleType.id}/makes`)
      .then(response => response.json())
      .then(data => {
        const makes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setMakes(makes)
      })
    window.fetch(`/api/v1/vehicle_types/${props.vehicleType.id}/vehicle_subtypes`)
      .then(response => response.json())
      .then(data => {
        const vehicleSubtypes = data.map(m => {
          return { label: m.name, value: m.id }
        })
        setVehicleSubtypes(vehicleSubtypes)
      })
    window.fetch('/api/v1/wheel_sizes')
      .then(response => response.json())
      .then(data => {
        setWheelSizes(data)
      })
  }, [])

  return (
    <>
      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Categoría</span>
        <Select
          name='q[vehicle_subtype_id_eq]'
          className='block w-full rounded'
          options={vehicleSubtypes}
          value={vehicleSubtypes.find(x => x.value === parseInt(vehicleSubtypeId))}
          onChange={handleVehicleSubtypeChange}
          isClearable
          blurInputOnSelect
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <div className='block space-y-2'>
        <span className='font-bold text-gray-700'>Marca</span>
        <Select
          name='q[make_id_eq]'
          className='block w-full rounded'
          options={makes}
          defaultValue={makes.find(x => x.value === parseInt(makeId))}
          onChange={handleMakeChange}
          isClearable
          blurInputOnSelect
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <RangeInput
        label='Precio'
        name='price'
        valueGteq={priceGteq}
        setValueGteq={setPriceGteq}
        valueLteq={priceLteq}
        setValueLteq={setPriceLteq}
      />

      <div className='block space-y-2'>
        <div className='font-bold text-gray-700'>Ubicación</div>
        <Select
          name='q[location_in][]'
          className='block w-full rounded'
          options={locationOptions}
          value={locationIn}
          onChange={handleLocationChange}
          isClearable
          blurInputOnSelect
          isMulti
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'No hay opciones'}
          filterOption={FilterOption}
        />
      </div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[receives_eq]' value='true' checked={receivesEq === 'true'} onChange={(e) => setReceivesEq(e.target.checked.toString())} />
              <span className='ml-2 font-bold text-gray-700'>Recibe vehículo como pago</span>
            </label>
          </div>
        </label>
      </div>

      <div>
        <label className='block space-y-2'>
          <div>
            <label className='inline-flex items-center'>
              <input className='form-checkbox' type='checkbox' name='q[owner_type_eq]' value='CustomShop' checked={ownerTypeEq === 'CustomShop'} onChange={handleOwnerTypeChange} />
              <span className='ml-2 font-bold text-gray-700'>Sólo agencias y comercios</span>
            </label>
          </div>
        </label>
      </div>

      <CheckboxList
        label='Tamaño'
        options={props.frameSizeOptions}
        checkedOptions={frameSizeIn}
        onChange={handleFrameSizeInChange}
        name='frame_size_in'
      />

      <CheckboxList
        label='Género'
        options={props.frameGenderOptions}
        checkedOptions={frameGenderIn}
        onChange={handleFrameGenderInChange}
        name='frame_gender_in'
      />

      <CheckboxList
        label='Material del marco'
        options={frameMaterials.map(frameMaterial => [frameMaterial.name, frameMaterial.id.toString()])}
        checkedOptions={frameMaterialIdIn}
        onChange={handleFrameMaterialIdInChange}
        name='frame_material_id_in'
      />

      <CheckboxList
        label='Tipo de freno'
        options={bicycleBrakes.map(bicycleBrake => [bicycleBrake.name, bicycleBrake.id.toString()])}
        checkedOptions={brakeTypeIdIn}
        onChange={handleBrakeTypeIdInChange}
        name='bicycle_brake_id_in'
      />

      <CheckboxList
        label='Tipo de suspensión'
        options={props.suspensionTypeOptions}
        checkedOptions={suspensionTypeIn}
        onChange={handleSuspensionTypeInChange}
        name='suspension_type_in'
      />

      <CheckboxList
        label='Recorrido de suspensión'
        options={props.suspensionTravelOptions}
        checkedOptions={suspensionTravelIn}
        onChange={handleSuspensionTravelInChange}
        name='suspension_travel_in'
      />

      <CheckboxList
        label='Tamaño de llanta'
        options={wheelSizes.map(wheelSize => [wheelSize.name, wheelSize.id.toString()])}
        checkedOptions={wheelSizeIdIn}
        onChange={handleWheelSizeIdInChange}
        name='wheel_size_id_in'
      />
    </>
  )
}

export default BicyclesSearch
