import React from 'react'

const Title = ({ text }) => {
  return (
    <div className='container mt-4 mx-auto md:mt-8'>
      <div className='items-center md:flex md:space-x-10'>
        <div className='border-blue-600 absolute md:border-b md:flex-1 md:relative' />
        <p className='font-bold text-2xl text-center text-gray-700 md:font-normal'>{text}</p>
        <div className='border-blue-600 absolute md:border-b md:flex-1 md:relative' />
      </div>
    </div>
  )
}

export default Title
