import React from 'react'

const HiddenFields = ({ customShop }) => {
  return (
    <>
      <input type='hidden' name='custom_shop[name]' value={customShop.name} />
      <input type='hidden' name='custom_shop[website]' value={customShop.website} />
      <input type='hidden' name='custom_shop[about_us]' value={customShop.about_us} />
      <input type='hidden' name='custom_shop[all_vehicles_warranty]' value={customShop.all_vehicles_warranty} />
      <input type='hidden' name='custom_shop[selected_vehicles_warranty]' value={customShop.selected_vehicles_warranty} />
      <input type='hidden' name='custom_shop[receives]' value={customShop.receives} />
      <input type='hidden' name='custom_shop[financing]' value={customShop.financing} />
      <input type='hidden' name='custom_shop[buys]' value={customShop.buys} />
      <input type='hidden' name='custom_shop[consignment]' value={customShop.consignment} />
      <input type='hidden' name='custom_shop[additional_benefits]' value={customShop.additional_benefits} />

      {!customShop.id && (
        <>
          <input type='hidden' name='custom_shop[company_id]' value={customShop.company_id} />
          <input type='hidden' name='custom_shop[company_name]' value={customShop.company_name} />
          <input type='hidden' name='custom_shop[legal_representative_name]' value={customShop.legal_representative_name} />
          <input type='hidden' name='custom_shop[slug]' value={customShop.slug} />
        </>
      )}

      {customShop.useUserEmail || (
        <input type='hidden' name='custom_shop[email]' value={customShop.email} />
      )}

      {customShop.oldBranches?.filter(oldBranch => !customShop.branches.some(branch => branch.id === oldBranch.id)).map((branch, key) => (
        <div key={key}>
          <input type='hidden' name='custom_shop[branches_attributes][][_destroy]' value />
          <input type='hidden' name='custom_shop[branches_attributes][][id]' value={branch.id} />
        </div>
      ))}

      {customShop.branches?.map((branch, key) => (
        <div key={key}>
          <input type='hidden' name='custom_shop[branches_attributes][][id]' value={branch.id} />
          <input type='hidden' name='custom_shop[branches_attributes][][address]' value={branch.address} />
          <input type='hidden' name='custom_shop[branches_attributes][][province]' value={branch.province} />

          {!branch.phones[1]?.phone && (
            <div>
              <input type='hidden' name='custom_shop[branches_attributes][][phones_attributes][][_destroy]' value />
              <input type='hidden' name='custom_shop[branches_attributes][][phones_attributes][][id]' value={branch.phones[1]?.id} />
            </div>
          )}

          <input type='hidden' name='custom_shop[branches_attributes][][phones_attributes][][id]' value={branch.phones[0].id} />
          <input type='hidden' name='custom_shop[branches_attributes][][phones_attributes][][phone]' value={branch.phones[0].phone} />
          <input type='hidden' name='custom_shop[branches_attributes][][phones_attributes][][has_whatsapp]' value={branch.phones[0].has_whatsapp} />

          {branch.phones[1]?.phone && (
            <div>
              <input type='hidden' name='custom_shop[branches_attributes][][phones_attributes][][id]' value={branch.phones[1].id} />
              <input type='hidden' name='custom_shop[branches_attributes][][phones_attributes][][phone]' value={branch.phones[1].phone} />
              <input type='hidden' name='custom_shop[branches_attributes][][phones_attributes][][extension]' value={branch.phones[1].extension} />
            </div>
          )}
        </div>
      ))}

      {customShop.oldSchedules && Object.keys(customShop.oldSchedules).map((day, key) => (
        customShop.oldSchedules[day].map((oldSchedule, index) => (
          (!customShop.schedules || !customShop.schedules[day] || !customShop.schedules[day][index].id === oldSchedule.id) && (
            <div key={key}>
              <input type='hidden' name='custom_shop[schedules_attributes][][_destroy]' value />
              <input type='hidden' name='custom_shop[schedules_attributes][][id]' value={oldSchedule.id} />
            </div>
          )
        ))
      ))}

      {customShop.schedules && Object.keys(customShop.schedules).map(day => (
        customShop.schedules[day].map((schedule, key) => (
          schedule.from_time?.value && schedule.to_time?.value && (
            <div key={key}>
              <input type='hidden' name='custom_shop[schedules_attributes][][id]' value={schedule.id} />
              <input type='hidden' name='custom_shop[schedules_attributes][][day]' value={day} />
              <input type='hidden' name='custom_shop[schedules_attributes][][from_time]' value={schedule.from_time.value} />
              <input type='hidden' name='custom_shop[schedules_attributes][][to_time]' value={schedule.to_time.value} />
            </div>
          )
        ))
      ))}
    </>
  )
}

export default HiddenFields
