import React, { useState } from 'react'

import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'

const CheckRequestFormWizard = ({ ad, amount, exchangeRate, title, user, formAuthenticityToken }) => {
  const [step, setStep] = useState(1)
  const nextStep = () => { setStep(step + 1) }
  const prevStep = () => { setStep(step - 1) }

  const [userData, setUserData] = useState(user)

  return (
    <>
      <div className='pt-16 pb-2 bg-blue-50 md:py-2'>
        <div className='container mx-auto'>
          <div className='grid grid-cols-1 text-sm text-blue-900 md:grid-cols-3'>
            <div className='absolute invisible md:visible md:relative'>{title}</div>
            <div className='text-center'>{title} - Paso {step} de 3</div>
          </div>
        </div>
      </div>

      {step === 1 && (
        <Step1
          amount={amount}
          exchangeRate={exchangeRate}
          nextStep={nextStep}
        />
      )}
      {step === 2 && (
        <Step2
          nextStep={nextStep}
          prevStep={prevStep}
          setUserData={setUserData}
          user={user}
          userData={userData}
        />
      )}
      {step === 3 && (
        <Step3
          ad={ad}
          amount={amount}
          exchangeRate={exchangeRate}
          prevStep={prevStep}
          user={user}
          userData={userData}
          formAuthenticityToken={formAuthenticityToken}
        />
      )}
    </>
  )
}

export default CheckRequestFormWizard
