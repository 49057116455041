import React, { useEffect, useState } from 'react'

import AtvsSearch from './vehicle_types/AtvsSearch'
import BicyclesSearch from './vehicle_types/BicyclesSearch'
import CarsSearch from './vehicle_types/CarsSearch'
import IndustrialSearch from './vehicle_types/IndustrialSearch'
import MotorbikesSearch from './vehicle_types/MotorbikesSearch'
import NauticalSearch from './vehicle_types/NauticalSearch'

const Search = props => {
  const vehicleTypeIsAtvs = props.vehicleType.name === 'ATVs'
  const vehicleTypeIsBicycles = props.vehicleType.name === 'Bicicletas'
  const vehicleTypeIsCars = props.vehicleType.name === 'Autos'
  const vehicleTypeIsIndustrial = props.vehicleType.name === 'Industrial y Maq. pesada'
  const vehicleTypeIsMotorbikes = props.vehicleType.name === 'Motocicletas'
  const vehicleTypeIsNautical = props.vehicleType.name === 'Náutico'

  const [notification, setNotification] = useState(null)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    window.fetch(`/api/v1/notifications${window.location.search}`)
      .then(response => response.json())
      .then(data => setNotification(data))
  }, [])

  const handleChange = (e) => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = Object()

    urlParams.forEach((value, key) => {
      if (key.substring(key.length - 2, key.length) === '[]') {
        key = key.substring(2, key.length - 3)
        if (params[key] === undefined) { params[key] = [] }
        params[key].push(value)
      } else {
        key = key.substring(2, key.length - 1)
        params[key] = value
      }
    })

    setDisabled(true)

    if (e.target.checked) {
      window.fetch('/api/v1/notifications', {
        method: 'POST',
        body: JSON.stringify(params)
      }).then(response => response.json())
        .then(data => {
          setNotification(data)
          setDisabled(false)
        })
    } else {
      window.fetch(`/api/v1/notifications/${notification.id}`, {
        method: 'DELETE'
      }).then(response => {
        setNotification(null)
        setDisabled(false)
      })
    }
  }

  const handleClick = () => {
    window.fbq('track', 'Search')

    window.gtag('event', 'conversion', { send_to: 'AW-306649816/9LUpCJKC69ADENi1nJIB' })
  }

  const handleReset = (e) => {
    const params = new URLSearchParams(window.location.search)
    window.location.replace(`${window.location.protocol}${window.location.pathname}?q%5Bvehicle_type_id_eq%5D=${params.get('q[vehicle_type_id_eq]') || 1}`)
  }

  return (
    <form action={props.action} className='md:pr-12 space-y-11 md:space-y-8'>
      {!window.location.search.match(/^\?q%5Bvehicle_type_id_eq%5D=\d$/g) && (
        <div className='block p-4 rounded bg-blue-50'>
          {props.user !== null
            ? (
              <label className='flex items-center'>
                <input className='form-checkbox' type='checkbox' checked={notification !== null} onChange={handleChange} disabled={disabled} />
                <span className='ml-4 text-sm text-gray-700'>Notificarme por mail cuando haya nuevos anuncios que coincidan con mi búsqueda</span>
              </label>
              )
            : <p className='text-gray-700'><a href={props.login_url} className='underline'>Inicie sesión</a> para recibir notificaciones sobre esta búsqueda</p>}
        </div>
      )}

      {vehicleTypeIsAtvs && <AtvsSearch {...props} />}
      {vehicleTypeIsBicycles && <BicyclesSearch {...props} />}
      {vehicleTypeIsCars && <CarsSearch {...props} />}
      {vehicleTypeIsIndustrial && <IndustrialSearch {...props} />}
      {vehicleTypeIsMotorbikes && <MotorbikesSearch {...props} />}
      {vehicleTypeIsNautical && <NauticalSearch {...props} />}

      <div className='grid grid-cols-5 pb-8 md:pb-0 gap-7'>
        <button type='button' className='col-span-3 px-2 py-2 bg-transparent border border-gray-600 rounded' onClick={handleReset}>Limpiar campos</button>
        <input type='submit' value='Buscar' className='col-span-2 px-2 py-2 text-white bg-blue-600 rounded' onClick={handleClick} />
      </div>

      <input type='hidden' name='q[vehicle_type_id_eq]' value={props.vehicleType.id} />
      {props.q.s && <input type='hidden' name='q[s]' value={props.q.s} />}
    </form>
  )
}

export default Search
