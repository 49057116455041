import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import PlanSelector from './PlanSelector'

const SubscriptionForm = props => {
  const [selectedPlanId, setSelectedPlanId] = useState()
  const [paypalError, setPaypalError] = useState(false)
  const [paypalSuccessful, setPaypalSuccessful] = useState(false)
  const formRef = useRef()
  const { register, setValue } = useForm()

  const createSubscription = (data, actions) => {
    return actions.subscription.create({
      plan_id: selectedPlanId
    })
  }

  const handleApprove = (data, actions) => {
    setValue('custom_shop[paypal_subscription_id]', data.subscriptionID)
    setPaypalSuccessful(true)
    formRef.current.submit()
  }

  const handleError = error => {
    if (error) {
      setPaypalError(true)
    }
  }
  return (
    <PayPalScriptProvider options={{ 'client-id': document.querySelector('meta[name="paypal-client-id"]').getAttribute('content'), vault: true, intent: 'subscription' }}>
      <form action='/subscription' method='post' ref={formRef} className='text-gray-700'>
        <input type='hidden' name='authenticity_token' value={props.formAuthenticityToken} />

        <input type='hidden' name='custom_shop[paypal_plan_id]' value={selectedPlanId} />

        <PlanSelector
          paypalPlan1Id={props.paypal_plan_1_id}
          paypalPlan2Id={props.paypal_plan_2_id}
          paypalPlan3Id={props.paypal_plan_3_id}
          selectedPlanId={selectedPlanId}
          setSelectedPlanId={setSelectedPlanId}
          freeTrial={!props.customShop.freeTrialEndsAt}
        />

        {selectedPlanId && (
          <div className='container w-full md:w-3/4 mx-auto p-12'>
            <input type='hidden' {...register('custom_shop[paypal_subscription_id]', { required: true })} />

            <div className='w-full md:w-1/2 mx-auto'>
              {paypalError && (
                <div className='text-center'>
                  Hubo un problema procesando el pago, por favor vuelva a intentarlo.
                </div>
              )}

              {!paypalSuccessful && (
                <PayPalButtons
                  createSubscription={createSubscription}
                  onApprove={handleApprove}
                  onError={handleError}
                />
              )}
            </div>
          </div>
        )}
      </form>
    </PayPalScriptProvider>
  )
}

export default SubscriptionForm
