import React, { useState } from 'react'

import Search from '../Search'

const SearchOptions = props => {
  const [searchMenuIsOpen, setSearchMenuIsOpen] = useState(false)
  const [sortMenuIsOpen, setSortMenuIsOpen] = useState(false)

  const handleSearchMenuButton = (e) => {
    setSortMenuIsOpen(false)
    setSearchMenuIsOpen(!searchMenuIsOpen)
  }

  const handleSortMenuButton = (e) => {
    setSearchMenuIsOpen(false)
    setSortMenuIsOpen(!sortMenuIsOpen)
  }

  return (
    <>
      <div className='flex bg-white shadow'>
        <button className={`flex-1 py-4 ${searchMenuIsOpen ? 'bg-blue-600 text-white' : 'bg-blue-50 text-blue-700'}`} onClick={handleSearchMenuButton}>
          <div className='flex items-center mx-auto space-x-2 w-max'>
            <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg' className='stroke-current'>
              <path d='M2.018 2.018a.917.917 0 00-.268.649v2.37c0 .243.097.476.269.648l5.88 5.88a.917.917 0 01.268.648v6.037l3.666-3.667v-2.37c0-.243.097-.476.269-.648l5.88-5.88a.917.917 0 00.268-.648v-2.37a.917.917 0 00-.917-.917H2.667a.917.917 0 00-.649.268z' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <p>Filtros de búsqueda</p>
          </div>
        </button>
        <button className={`flex-1 py-4 ${sortMenuIsOpen ? 'bg-blue-600 text-white' : 'text-gray-700'}`} onClick={handleSortMenuButton}>
          <div className='flex items-center mx-auto space-x-2 w-max'>
            <svg width='16' height='20' fill='none' xmlns='http://www.w3.org/2000/svg' className='stroke-current'>
              <path d='M1.583 8.167L8 1.75m0 0l6.417 6.417M8 1.75v16.5' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <p>Ordenar por</p>
          </div>
        </button>
      </div>
      {sortMenuIsOpen && (
        <div className='z-10 text-gray-700 divide-y shadow bg-gray-50 divide-solid'>
          {props.options.map(option => {
            return <a key={option.title} href={option.url} className={`block text-lg pl-5 py-5 ${props.options.find(x => x.url.includes(props.currentSort?.replace(' ', '+')))?.url === option.url ? 'text-blue-600 font-bold' : 'text-gray-700'}`}>{option.title}</a>
          })}
        </div>
      )}

      {searchMenuIsOpen && (
        <div className='z-10 overflow-auto' style={{ height: 'calc(100vh - 9.5em)' }}>
          <div className='p-4 shadow bg-gray-50'>
            <Search {...props.searchParams} />
          </div>
          <div className='h-14' />
        </div>
      )}
    </>
  )
}

export default SearchOptions
