import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import Buttons from '../../shared/Buttons'
import Title from '../../shared/Title'

const VehicleTypesWithHomePageUpsale = [1, 4, 5]

const Step1 = ({ ad, nextStep, prevStep, setAd, upsalePrices, faqPath }) => {
  const methods = useForm({ defaultValues: ad })

  const [totalPrice, setTotalPrice] = useState(ad.upsales?.map(upsale => upsalePrices[upsale]).reduce((a, b) => a + b, 0))

  const handleChange = (e) => {
    setTotalPrice(totalPrice + (e.target.checked ? 1 : -1) * upsalePrices[e.target.value])

    if (e.target.checked) {
      window.fbq('trackCustom', 'Upsale')

      window.gtag('event', 'conversion', { send_to: 'AW-306649816/OGECCOD2n4ADENi1nJIB' })
    }
  }

  const onSubmit = data => {
    setAd(Object.assign(ad, data))
    nextStep()
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Title text='Personaliza tu anuncio' />

        <div className='container pt-4 mx-auto text-gray-700 xl:w-3/4 md:pb-12 md:pt-6'>
          <p className='mx-8 md:text-center md:mx-0'>Puede añadir extras a su anuncio para que tenga prioridad en los resultados de búsqueda, se destaque visualmente o sea publicado en nuestras redes sociales, entre otros. Puede seleccionar todos los que quiera, cada uno tiene una duración de un mes.</p>

          <div className={`grid grid-cols-1 gap-7 px-5 pt-16 md:gap-5 md:${VehicleTypesWithHomePageUpsale.includes(ad.vehicle_type_id) ? 'grid-cols-4' : 'grid-cols-3 md:w-3/4'} md:mx-auto`}>

            <div>
              <span className='float-left text-blue-600 md:text-sm -mt-7'>Recomendado para su anuncio</span>
              <div className='p-5 bg-white border-2 border-blue-600 rounded pb-7 md:pb-5 md:h-full'>
                <p className='text-xl font-bold font-raleway md:text-xl md:text-3xl'>Destacar y Promover</p>
                <p className='pt-1 md:pt-2'>Su anuncio se destacará con un borde azul y tendrá prioridad en los resultados de búsqueda sobre los no destacados.</p>
                <p className='pt-1 md:pt-2'>Adicionalmente, su vehículo se promoverá como anuncio en redes sociales por 5 días (pauta $1 al día).</p>

              </div>
              <div className='block pt-1 m-auto text-lg md:pt-2 w-max'>
                {ad.existentUpsales.includes('highlight')
                  ? (
                    <div className='opacity-50'>
                      <input type='checkbox' defaultChecked disabled />
                      <span className='ml-2 text-gray-600'>10 usd</span>
                    </div>
                    )
                  : (
                    <>
                      <input type='checkbox' {...methods.register('upsales[]')} onChange={handleChange} value='highlight' />
                      <span className='ml-2 text-blue-600'>10 usd</span>
                    </>
                    )}
              </div>
            </div>
            <div>
              <div className='relative'>
                <img className='absolute right-0 mr-2' src={require('../../../images/ribbon.svg')} />
              </div>
              <div className='p-5 bg-white border border-gray-400 rounded pb-7 md:pb-5 md:h-full'>
                <p className='pr-6 text-xl font-bold font-raleway md:text-3xl'>Añadir lazo</p>
                <p className='pt-1 md:pt-2 pr-'>Se añade un resaltador visual a su anuncio en forma de un pequeño lazo. Ayuda a llamar la atención de los usuarios pero no modifica su posición en los resultados de búsqueda.</p>
              </div>
              <div className='block pt-1 m-auto text-lg md:pt-2 w-max'>
                {ad.existentUpsales.includes('ribbon')
                  ? (
                    <div className='opacity-50'>
                      <input type='checkbox' defaultChecked disabled />
                      <span className='ml-2 text-gray-600'>2 usd</span>
                    </div>
                    )
                  : (
                    <>
                      <input type='checkbox' {...methods.register('upsales[]')} onChange={handleChange} value='ribbon' />
                      <span className='ml-2 text-blue-600'>2 usd</span>
                    </>
                    )}
              </div>
            </div>
            <div>
              <div className='p-5 bg-white border border-gray-400 rounded pb-7 md:pb-5 md:h-full'>
                <p className='text-xl font-bold font-raleway md:text-3xl'>Publicar en Redes Sociales</p>
                <p className='pt-1 md:pt-2'>Su anuncio se publicará (una vez) en las cuentas oficiales de Dunu 506 en Instagram y Facebook.</p>
              </div>
              <div className='block pt-1 m-auto text-lg md:pt-2 w-max'>
                {ad.existentUpsales.includes('social_media')
                  ? (
                    <div className='opacity-50'>
                      <input type='checkbox' defaultChecked disabled />
                      <span className='ml-2 text-gray-600'>5 usd</span>
                    </div>
                    )
                  : (
                    <>
                      <input type='checkbox' {...methods.register('upsales[]')} onChange={handleChange} value='social_media' />
                      <span className='ml-2 text-blue-600'>5 usd</span>
                    </>
                    )}
              </div>
            </div>
            {VehicleTypesWithHomePageUpsale.includes(ad.vehicle_type_id) && (
              <div>
                <div className='p-5 bg-white border border-gray-400 rounded pb-7 md:pb-5 md:h-full'>
                  <p className='text-xl font-bold font-raleway md:text-3xl'>Publicar en Home Page</p>
                  <p className='pt-1 md:pt-2'>Su anuncio se publicará durante un mes en nuestra página principal. Los anuncios de página principal rotarán de forma equiparada para que tengan la misma visibilidad. Más información en <a href={faqPath} className='underline'>Preguntas Frecuentes.</a></p>
                </div>
                <div className='block pt-1 m-auto text-lg md:pt-2 w-max'>
                  {ad.existentUpsales.includes('homepage')
                    ? (
                      <div className='opacity-50'>
                        <input type='checkbox' defaultChecked disabled />
                        <span className='ml-2 text-gray-600'>15 usd</span>
                      </div>
                      )
                    : (
                      <>
                        <input type='checkbox' {...methods.register('upsales[]')} onChange={handleChange} value='homepage' />
                        <span className='ml-2 text-blue-600 disabled:opacity-50 disabled:text-gray-600'>15 usd</span>
                      </>
                      )}
                </div>
              </div>
            )}
            <input className='invisible position-absolute' type='checkbox' {...methods.register('upsales[]')} />
          </div>
        </div>

        <Buttons
          prevStep={prevStep}
          totalPrice={totalPrice}
          disabled={totalPrice < 1}
        />
      </form>
    </FormProvider>
  )
}

export default Step1
