import React, { useState } from 'react'

const AlertBanner = props => {
  const [show, setShow] = useState(true)

  if (show) {
    return (
      <div class='container'>
        <div class='max-w-screen-2xl px-4 md:px-8 pb-4 mx-auto'>
          <div class='flex flex-wrap sm:flex-nowrap sm:justify-center sm:items-center bg-blue-600 rounded-lg shadow-lg relative sm:gap-3 px-4 sm:pr-8 ms:px-8 py-3'>
            <div class='order-1 sm:order-none w-11/12 sm:w-auto max-w-screen-sm inline-block text-white text-sm md:text-base mb-2 sm:mb-0'>{props.message}</div>
            <div class='order-2 sm:order-none w-1/12 sm:w-auto flex justify-end items-start sm:absolute sm:right-0 sm:mr-2 xl:mr-3'>
              <button onClick={() => setShow(false)} type='button' class='text-white hover:text-indigo-100 active:text-indigo-200 transition duration-100'>
                <svg xmlns='http://www.w3.org/2000/svg' class='w-5 xl:w-6 h-5 xl:h-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 18L18 6M6 6l12 12' />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default AlertBanner
