import React, { useState } from 'react'

import PasswordField from './fields/PasswordField'
import PhoneField from './fields/PhoneField'
import TextField from './fields/TextField'

const ProfileForm = props => {
  const [hasWhatsapp, setHasWhatsapp] = useState(props.user.hasWhatsapp)

  const handleHasWhatsappChange = e => {
    setHasWhatsapp(!hasWhatsapp)
    e.target.form.submit()
  }

  return (
    <div className='md:px-24 md:py-6 md:bg-white rounded md:shadow'>
      <div className='p-6 border-b'>
        <TextField
          formAuthenticityToken={props.formAuthenticityToken}
          initialValue={props.user.fullName}
          label='Nombre y apellido'
          name='user[full_name]'
        />
      </div>

      <div className='p-6 border-b'>
        <PhoneField
          formAuthenticityToken={props.formAuthenticityToken}
          initialValue={props.user.phone}
          label='Teléfono de contacto'
          name='user[phone]'
        />
        <div className='flex items-center'>
          <div className='flex-1'>
            <form action='/profile' method='post' data-remote='true'>
              <input type='hidden' name='_method' value='patch' />
              <input type='hidden' name='authenticity_token' value={props.formAuthenticityToken} />

              <label className='mt-2 inline-flex items-center'>
                <input type='hidden' name='user[has_whatsapp]' value='0' />
                <input className='form-checkbox' type='checkbox' name='user[has_whatsapp]' value='1' checked={hasWhatsapp === true} onChange={handleHasWhatsappChange} />
                <span className='ml-2 text-gray-700'>Prefiero que los compradores me contacten por WhatsApp</span>
              </label>
            </form>
          </div>
        </div>
      </div>

      <div className='p-6 border-b'>
        <TextField
          formAuthenticityToken={props.formAuthenticityToken}
          initialValue={props.user.email}
          label='Dirección de correo'
          name='user[email]'
        />
      </div>

      <div className='p-6 border-b'>
        <PasswordField
          formAuthenticityToken={props.formAuthenticityToken}
          label='Contraseña'
        />
      </div>

      <div className='p-6 border-b'>
        <p className='text-gray-700 text-sm'>Facebook</p>
        {props.user.facebookName
          ? <p className='ml-2 text-gray-700 underline'>{props.user.facebookName}</p>
          : <a href='/users/auth/facebook' data-method='post' className='ml-2 font-bold text-gray-700 underline'>Conectar con Facebook</a>}
      </div>

      <div className='p-6'>
        <p className='text-gray-700 text-sm'>Google</p>
        {props.user.googleName
          ? <p className='ml-2 text-gray-700 underline'>{props.user.googleName}</p>
          : <a href='/users/auth/google_oauth2' data-method='post' className='ml-2 font-bold text-gray-700 underline'>Conectar con Google</a>}
      </div>
    </div>
  )
}

export default ProfileForm
