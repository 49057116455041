import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import ScheduleSelector from './ScheduleSelector'

const Schedule = ({ day, customShop }) => {
  const { getValues, unregister } = useFormContext()

  const [closed, setClosed] = useState(customShop.id ? (getValues(`schedules[${day[1]}][0]`) === undefined) : false)
  const [splitSchedule, setSplitSchedule] = useState(getValues(`schedules[${day[1]}][1]`) !== undefined)

  const handleClosedChanged = (e) => {
    setClosed(e.target.checked)
    unregister(`schedules[${day[1]}]`)
  }

  const handleSplitScheduleChanged = (e) => {
    setSplitSchedule(e.target.checked)
    unregister(`schedules[${day[1]}][1]`)
  }

  return (
    <div>
      <div key={day[1]} className='flex space-x-5 py-4'>
        <div className='md:flex space-y-5 w-full md:space-y-0 md:space-x-5'>
          <span className='mt-2 w-20 font-bold'>{day[0]}</span>
          <div className='w-full md:w-1/3'>
            <ScheduleSelector
              name={`schedules[${day[1]}][0]`}
              disabled={closed}
            />
          </div>
          {!closed && (
            <>
              {splitSchedule && (
                <div className='flex w-full md:space-x-5 md:w-1/3'>
                  <span className='invisible absolute mt-2 md:visible md:relative'>y</span>
                  <ScheduleSelector
                    name={`schedules[${day[1]}][1]`}
                  />
                </div>
              )}
              <label className='inline-block w-1/2 md:pt-2 md:w-max'>
                <input type='checkbox' onChange={handleSplitScheduleChanged} defaultChecked={splitSchedule} />
                <span className='ml-2'>Horario divido</span>
              </label>
            </>
          )}

          <label className='inline-block w-1/2 md:pt-2 md:w-max'>
            <input type='checkbox' defaultChecked={closed} onChange={handleClosedChanged} />
            <span className='ml-2'>Cerrado todo el día</span>
          </label>
        </div>
      </div>
    </div>
  )
}

export default Schedule
