import React, { useState } from 'react'

import ConfirmationDialog from '../shared/ConfirmationDialog'

const DuplicateAd = props => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const handleClick = () => {
    setDialogIsOpen(true)
  }

  return (
    <>
      <ConfirmationDialog
        title='Duplicar anuncio'
        description={`Se creará una copia de este anuncio, la cual aparecerá en la sección Pendientes.
        La copia mantendrá los datos y fotos, los cuales usted podrá editar antes de volver a publicar el anuncio.`}
        url={props.url}
        method='put'
        isOpen={dialogIsOpen}
        setIsOpen={setDialogIsOpen}
      />
      <button className='block px-4 py-2 mx-auto text-center text-white bg-blue-600 rounded' onClick={() => handleClick()}> Duplicar </button>
    </>
  )
}

export default DuplicateAd
