import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import NumberFormat from 'react-number-format'

const Branch = ({ id, provinces, onRemove }) => {
  const { control, formState: { errors }, register } = useFormContext()

  return (
    <div className='my-6 space-y-6 md:space-y-8 md:my-8'>
      {id !== 0 && (<input type='button' className='float-right text-sm text-gray-500 bg-transparent' value='Eliminar sucursal' onClick={() => onRemove(id)} />)}
      <div>
        <span className='mt-2 font-bold text-blue-600 flex-0'>Sucursal {id === 0 ? 'principal' : id + 1}</span>
        {id === 0 && (
          <p className='mt-2'>En todos sus anuncios se mostrarán los datos de esta sucursal a menos que indique otra.</p>
        )}
      </div>
      <div className='space-y-6 md:flex md:space-y-0 md:space-x-8'>
        <div className='flex-1'>
          <div className='w-full space-y-1 md:flex md:space-x-2 md:space-y-0'>
            <span className='my-auto flex-0 md:font-bold'>Dirección</span>
            <input type='text' className='block w-full rounded md:flex-1' {...register(`branches[${id}][address]`, { required: 'Es obligatorio' })} />
          </div>
          {errors.branches && errors.branches[id]?.address && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.branches[id].address.message}</p>}
        </div>

        <div className='flex-0'>
          <div className='w-full space-y-1 md:flex md:space-x-2 md:space-y-0'>
            <span className='my-auto flex-0 md:font-bold'>Provincia</span>
            <select className='block w-full rounded md:flex-1' {...register(`branches[${id}][province]`, { required: 'Es obligatorio' })}>
              <option />
              {provinces.map(option => {
                return <option key={option[1]} value={option[1]}>{option[0]}</option>
              })}
            </select>
          </div>
          {errors.branches && errors.branches[id]?.province && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.branches[id].province.message}</p>}
        </div>
      </div>

      <div>
        <div className='md:flex md:space-x-4'>
          <div className='w-2/3 space-y-1 md:flex md:space-x-2 md:w-max md:space-y-0'>
            <span className='w-40 my-auto flex-0 md:font-bold'>Teléfono celular</span>
            <div className='space-x-2'>
              <Controller
                name={`branches[${id}][phones][0][phone]`}
                control={control}
                rules={{ required: 'Es obligatorio' }}
                render={({ field: { ref, ...field } }) => (
                  <NumberFormat
                    {...field}
                    getInputRef={ref}
                    className='w-full rounded'
                    format='+506 ####-####'
                  />
                )}
              />
            </div>
          </div>
          <label className='block mt-2'>
            <input type='checkbox' {...register(`branches[${id}][phones][0][has_whatsapp]`)} />
            <span className='ml-2'>Permitir contactarse por WhatsApp</span>
          </label>
        </div>
        {errors.branches && errors.branches[id]?.phones && errors.branches[id]?.phones[0]?.phone && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.branches[id].phones[0].phone.message}</p>}
      </div>

      <div className='flex space-x-4'>
        <div className='w-2/3 space-y-1 md:flex md:space-x-2 md:w-max md:space-y-0'>
          <span className='w-40 my-auto flex-0 md:font-bold'>Teléfono secundario</span>
          <div className='space-x-2'>
            <Controller
              name={`branches[${id}][phones][1][phone]`}
              control={control}
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  className='w-full rounded'
                  format='+506 ####-####'
                />
              )}
            />
          </div>
        </div>
        <div className='w-1/3 space-y-1 md:flex md:space-x-2 md:w-max md:space-y-0'>
          <span className='my-auto flex-0 md:font-bold'>Ext.</span>
          <input type='number' className='block w-full rounded' {...register(`branches[${id}][phones][1][extension]`)} />
        </div>
      </div>
    </div>
  )
}

export default Branch
